<template>
  <div class="w-full">
    <div v-if="selectedOptions && onlineTournament"
         class="card card-blue-700 mx-1 md:mx-2 mt-4 flex-grow ">
      <div class="card-header">
        Competitors
      </div>
      <div class="card-body" id="printArea">
        <div>
          <form-switch
            @toggled="allowedToggle()"
            :model="onlineTournament.allow_edits"
            :id="'AllowEditsId'"
            :label="'Allow User Edits'"
            :sub-label="'This allows users to edit their own registration details. ' +
                     'It is generally a good idea to shut this off before the tournament starts.'"/>
        </div>
        <div class="flex flex-col">
          <div class="flex justify-end">
            <a @click.prevent="email" href="" class="text-gray-600 ml-3" title="Copy table">
              Email <i class="far fa-envelope"></i>
            </a>
            <a @click.prevent="download" href="" class="text-gray-600 ml-3" title="Copy table">Download
              CSV <i class="far fa-download"></i></a>
            <a @click.prevent="copy" href="" class="text-gray-600 ml-3" title="Copy table">Copy <i
              class="far fa-paperclip"></i></a>
            <a @click.prevent="printSelection" href="" class="text-gray-600 ml-3"
               title="Print invoice">Print <i class="far fa-print"></i></a>
          </div>
          <div class="bg-blue-100 shadow-inner pb-2 mb-2 flow-root" @click="getTournament">
            <div v-for="(detail,index) in onlineTournament.regform.filter(f => f.multiple !== null)"
                 class="btn-group ml-2">
              <div
                v-if="selectedOptions && selectedOptions.find(f => f.name === detail.option).selected"
                @click="selectedOptions.find(f => f.name === detail.option).selected = null"
                class="btn-sm btn-outline-gray-600">
                X
              </div>
              <dropdown :key="index + 'detail'">
                <template v-slot:trigger>
                  <button type="button" class="btn-sm btn-gray-600 dropdown-toggle">
                    {{ detail.name }}
                    {{ selectedOption(detail) }}
                    <i class="fas fa-caret-down"></i>
                  </button>
                </template>
                <a class="dropdown-item" href=""
                   @click.prevent="selectedOptions.find(f => f.name === detail.option).selected = 'none'">
                  none selected
                </a>
                <a v-for="options in detail.multiple" class="dropdown-item" href=""
                   @click.prevent="selectedOptions.find(f => f.name === detail.option).selected = options">
                  {{ options.label }}
                </a>
              </dropdown>
            </div>
            <div class="ml-2 btn-group">
              <div v-if="selectedTarget" @click="selectedTarget = null" class="btn-sm btn-outline-gray-600">
                X
              </div>
              <dropdown :key="'target_dropdown'">
                <template v-slot:trigger>
                  <button type="button" class="btn-sm btn-gray-600 dropdown-toggle">
                    Target
                    {{ !selectedTarget ? '' : ': ' + (selectedTarget === 'none' ? 'none' : selectedTarget.name) }}
                    <i class="fas fa-caret-down"></i>
                  </button>
                </template>
                <a class="dropdown-item" href="" @click.prevent="selectedTarget = 'none'">none
                  selected</a>
                <a v-for="target in onlineTournament.targets" class="dropdown-item" href=""
                   @click.prevent="selectedTarget = target">{{ target.name }}</a>
              </dropdown>
            </div>
            <div class="dropdown ml-2 btn-group">
              <div v-if="selectedStatus" @click="selectedStatus = null" class="btn-sm btn-outline-gray-600">X</div>
              <dropdown>
                <template v-slot:trigger>
                  <button type="button" class="btn-sm btn-gray-600 dropdown-toggle">
                    Status{{ !selectedStatus ? '' : ': ' + selectedStatus }}
                    <i class="fas fa-caret-down"></i>
                  </button>
                </template>
                <a v-for="status in ['paid','unpaid','withdrew','waitlist']" class="dropdown-item"
                   href=""
                   @click.prevent="selectedStatus = status">{{ status }}</a>
              </dropdown>
            </div>
            <div v-if="tournamentIn.payments.pricing_extras && tournamentIn.payments.pricing_extras.length"
                 class="dropdown ml-2 btn-group">
              <div v-if="selectedExtra" @click="selectedExtra = null" class="btn-sm btn-outline-gray-600">X</div>
              <dropdown>
                <template v-slot:trigger>
                  <button type="button" class="btn-sm btn-gray-600 dropdown-toggle">
                    Pricing Extras{{ !selectedExtra ? '' : ': ' + selectedExtra.name }}
                    <i class="fas fa-caret-down"></i>
                  </button>
                </template>
                <a v-for="extra in tournamentIn.payments.pricing_extras" class="dropdown-item"
                   href=""
                   @click.prevent="selectedExtra = extra">{{ extra.name }}</a>
              </dropdown>
            </div>
            
            <template v-if="Object.keys(round).length !== 0">
              <div class="dropdown ml-2 btn-group">
                <div v-if="selectedTime" @click="selectedTime = null"
                     class="btn-sm btn-outline-gray-600">X
                </div>
                <dropdown :key="'time_dropdown'">
                  <template v-slot:trigger>
                    <button type="button" class="btn-sm btn-gray-600 dropdown-toggle">
                      Line Time{{
                        !selectedTime ? '' : ': ' + (selectedTime === 'none' ? 'none' :
                          selectedTime.time)
                      }}
                      <i class="fas fa-caret-down"></i>
                    </button>
                  </template>
                  <a class="dropdown-item" href="" @click.prevent="selectedTime = 'none'">none
                    selected</a>
                  <a v-for="line_time in round.line_times"
                     class="dropdown-item"
                     href=""
                     @click.prevent="selectedTime = line_time">
                    {{ line_time.time }}
                  </a>
                </dropdown>
              </div>
              <div class="dropdown ml-2 btn-group">
                <div v-if="selectedLocation" @click="selectedLocation = null"
                     class="btn-sm btn-outline-gray-600">X
                </div>
                <dropdown>
                  <template v-slot:trigger>
                    <button type="button" class="btn-sm btn-gray-600 dropdown-toggle">
                      Location{{
                        !selectedLocation ? '' : ': ' + (selectedLocation === 'none' ?
                          'none' :
                          selectedLocation.name)
                      }}
                      <i class="fas fa-caret-down"></i>
                    </button>
                  </template>
                  <a class="dropdown-item" href="" @click.prevent="selectedLocation = 'none'">none
                    selected</a>
                  <a v-for="location in round.locations" class="dropdown-item" href=""
                     @click.prevent="selectedLocation = location">{{ location.name }}</a>
                </dropdown>
              </div>
              <div class="dropdown ml-2 btn-group">
                <div v-if="selectedBale" @click="selectedBale = null"
                     class="btn-sm btn-outline-gray-600">X
                </div>
                <dropdown>
                  <template v-slot:trigger>
                    <button type="button" class="btn-sm btn-gray-600 dropdown-toggle">
                      {{ baleWord }}{{ !selectedBale ? '' : ': ' + selectedBale }}
                      <i class="fas fa-caret-down"></i>
                    </button>
                  </template>
                  <a class="dropdown-item" href="" @click.prevent="selectedBale = 'none'">none
                    selected</a>
                  <a v-for="bale in allBales" class="dropdown-item" href=""
                     @click.prevent="selectedBale = bale">{{ bale }}</a>
                </dropdown>
              </div>
              <div class="dropdown ml-2 btn-group">
                <div v-if="selectedApproved" @click="selectedApproved = null"
                     class="btn-sm btn-outline-gray-600">X
                </div>
                <dropdown>
                  <template v-slot:trigger>
                    <button type="button" class="btn-sm btn-gray-600 dropdown-toggle">
                      Approved{{ !selectedApproved ? '' : ': ' + selectedApproved }}
                      <i class="fas fa-caret-down"></i>
                    </button>
                  </template>
                  <a v-for="approved in ['yes','no']" class="dropdown-item" href=""
                     @click.prevent="selectedApproved = approved">{{ approved }}</a>
                </dropdown>
              </div>
            </template>
            <div class="w-full sm:w-1/2 md:w-2/5 lg:w-1/3 xl:w-1/4 float-right mt-2 px-2">
              <div class="bs-form-box flex">
                <div class="">
                  <span class="input-group-text"><i class="fas fa-search"></i></span>
                </div>
                <input type="text" class="flex-grow" v-model="search">
                <div v-if="search !== ''" class="" @click="clearSearch">
                  <span class=""><i class="fas fa-times"></i></span>
                </div>
              </div>
            </div>
          
          </div>
        </div>
        <ul @click="resetRoundFilters" class="tabs mb-3 flex flex-wrap" role="tablist">
          <li class="selectedTab" id="mainTab">
            <a @click.prevent="selectTab({})" href="" class="active text-gray-900"
               role="tab">Competitor Details</a>
          </li>
          <li v-for="(r,index) in onlineTournament.rounds" class="tabItem" :id="'round_'+r.id">
            <a @click.prevent="selectTab(r)" href="" class="nav-link text-gray-900"
               role="tab">{{ r.name }}</a>
          </li>
        </ul>
        <div class="flex justify-between">
          <div v-if="Object.keys(round).length !== 0" @click="updateAll" class="btn-sm btn-gray-600">
            Update All
          </div>
        </div>
        <span class="float-right text-sm text-gray-600">
          <i class="fal fa-info-circle"></i> Most fields may be edited by clicking on the cell
        </span>
        <div v-if="Object.keys(round).length === 0" :class="printing ? '' :'table-responsive'">
          <table class="table table-bordered table-striped ">
            <thead>
            <tr class="bg-white">
              <th class="table-header">
                <div class="flex justify-between">
                  <div>First Name</div>
                  <div @click="sort('first_name')">
                    <i :class="sorted.includes('first_name')?'fa fa-arrow-down':'fa fa-arrow-up'"></i>
                  </div>
                </div>
              </th>
              <th class="table-header">
                <div class="flex justify-between">
                  <div>Last Name</div>
                  <div @click="sort('last_name')">
                    <i :class="sorted.includes('last_name')?'fa fa-arrow-down':'fa fa-arrow-up'"></i>
                  </div>
                </div>
              </th>
              <th class="table-header">Status</th>
              <th class="table-header">
                <div class="flex justify-between">
                  <div>Email</div>
                  <div @click="sort('email')">
                    <i :class="sorted.includes('email')?'fa fa-arrow-down':'fa fa-arrow-up'"></i>
                  </div>
                </div>
              </th>
              <th class="table-header" v-if="tournamentIn.line_times.length > 1">
                Requested Time
              </th>
              <th class="table-header"
                  v-for="extra in tournamentIn.payments.pricing_extras ?? []">
                {{ extra.name }} ({{ extra.type }}{{ extra.amount }})
              </th>
              <th class="table-header" v-for="detail in validFormHeaders">
                {{ detail.name }}
              </th>
              <th class="table-header" v-if="onlineTournament.targets.length > 1">Target</th>
              <th class="table-header">Delete</th>
            </tr>
            </thead>
            <tbody>
            <manage-competitor-row
              v-show="filteredCompetitors.length"
              v-for="(competitor, index) in filteredCompetitors"
              :round="round"
              :competitor="competitor"
              :key="competitor.id"
              :tournament="onlineTournament"
              @competitor-change="incrementChange"
              @delete="deleteCompetitor(competitor)"/>
            <tr v-show="!filteredCompetitors.length">
              <td colspan="10" class="table-empty text-center">There are no competitors</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div v-else class="table-responsive">
          <table class="table table-bordered table-striped">
            <thead>
            <tr>
              <th class="table-header">
                <div class="flex justify-between">
                  <div>First Name</div>
                  <div @click="sort('first_name')">
                    <i :class="sorted.includes('first_name')?'fa fa-arrow-down':'fa fa-arrow-up'"></i>
                  </div>
                </div>
              </th>
              <th class="table-header">
                <div class="flex justify-between">
                  <div>Last Name</div>
                  <div @click="sort('last_name')">
                    <i :class="sorted.includes('last_name')?'fa fa-arrow-down':'fa fa-arrow-up'"></i>
                  </div>
                </div>
              </th>
              <th class="table-header" v-if="onlineTournament.targets.length > 1">
                <div class="flex justify-between">
                  <div>Target</div>
                  <div @click="sort('target')">
                    <i :class="sorted.includes('target')?'fa fa-arrow-down':'fa fa-arrow-up'"></i>
                  </div>
                </div>
              </th>
              <th class="table-header">
                <div class="flex justify-between">
                  <div>Line Time</div>
                  <div @click="sort('line_time')">
                    <i :class="sorted.includes('line_time')?'fa fa-arrow-down':'fa fa-arrow-up'"></i>
                  </div>
                </div>
              </th>
              <th class="table-header">
                <div class="flex justify-between">
                  <div>Location</div>
                  <div @click="sort('location')">
                    <i :class="sorted.includes('location')?'fa fa-arrow-down':'fa fa-arrow-up'"></i>
                  </div>
                </div>
              </th>
              <th class="table-header">
                <div class="flex justify-between">
                  <div>{{ baleWord }}</div>
                  <div @click="sort('bale')">
                    <i :class="sorted.includes('bale')?'fa fa-arrow-down':'fa fa-arrow-up'"></i>
                  </div>
                </div>
              </th>
              <th class="table-header">
                <div class="flex justify-between">
                  <div>Position</div>
                  <div @click="sort('position')">
                    <i :class="sorted.includes('position')?'fa fa-arrow-down':'fa fa-arrow-up'"></i>
                  </div>
                </div>
              </th>
              <th class="table-header">Approved for Round</th>
            </tr>
            </thead>
            <tbody>
            <manage-competitor-row
              v-show="filteredCompetitors.length"
              v-for="competitor in filteredCompetitors"
              :round="round" :competitor="competitor"
              :key="competitor.id"
              :tournament="onlineTournament"
              @competitor-change="incrementChange"
              @update-available="updateAvailable"/>
            <tr v-show="!filteredCompetitors.length">
              <td colspan="10" class="table-empty text-center">There are no competitors matching
                selection.
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      
      </div>
    </div>
    <div v-else class="text-center flex-grow py-32">
      <h1 class="mt-4 text-5xl">Loading Competitor Details</h1>
      <span class="m-5"><i class="fas fa-spinner fa-spin fa-5x"></i></span>
    </div>
  </div>

</template>

<script>
import FormSwitch from "../../partials/FormSwitch.vue";
import ManageCompetitorRow from "./ManageCompetitorRow.vue";
import {VueAwesomePaginate} from "vue-awesome-paginate";
import {BsAlert, Toast} from "../../../mixins/swal_mixins";

export default {
  name: "ManageCompetitors",
  components: {VueAwesomePaginate, ManageCompetitorRow, FormSwitch},
  props: ['tournamentIn'],
  data() {
    return {
      round: {},
      selectedTime: null,
      selectedLocation: null,
      selectedBale: null,
      selectedApproved: null,
      selectedOptions: null,
      selectedTarget: null,
      selectedTeam: null,
      selectedStatus: null,
      selectedExtra: null,
      search: '',
      changeCount: 1,
      suiteChange: 0,
      printing: false,
      onlineTournament: null,
      sorted: [],
      currentPage: 1,
    }
  },
  computed: {
    validFormHeaders() {
      let list = [];
      for (let detail of this.onlineTournament.regform) {
        if (detail.enabled) {
          let options = ['name', 'email', 'first_name', 'last_name'];
          if (!(options.includes(detail.option) || ['header', 'waiver'].includes(detail.type))) {
            list.push(detail);
          }
        }
      }
      return list;
    },
    baleWord() {
      let text = this.$trans.choice('search.bale', 1);
      return `${text[0].toUpperCase()}${text.slice(1)}`;
    },
    comboTable() {
      let string = '';
      let details = {};
      string += '"Name","Email"';
      let vm = this;
      vm.onlineTournament.regform.forEach(function (detail) {
        if (detail.option !== 'name' && detail.option !== 'email' && detail.type !== 'header' && detail.type !== 'waiver') {
          string += ',"' + detail.name + '"';
          details[detail.option] = detail.multiple ? [] : '';
        }
      });
      if (this.tournamentIn.line_times.length > 1) {
        string += ',"Requested Time"';
      }
      vm.onlineTournament.rounds.forEach(function (round, index) {
        string += `,"Line Time ${index + 1}","Location ${index + 1}","`;
        string += vm.baleWord + ` ${index + 1}"`;
        string += `,"Position ${index + 1}"`
      });
      string += '\n';
      vm.filteredCompetitors.forEach(function (competitor) {
        string += '"' + competitor.name + '","' + competitor.email + '",';
        let competitorDetail = Object.assign({}, details);
        competitor.regform.forEach(function (detail) {
          competitorDetail[detail.option] = detail.multiple ? detail.multiple : detail.name;
        });
        vm.onlineTournament.regform.forEach(function (detail) {
          if (detail.option !== 'name' && detail.option !== 'email' && detail.type !== 'header' && detail.type !== 'waiver') {
            if (competitorDetail[detail.option] && competitorDetail[detail.option].length) {
              string += (detail.type === 'checkbox' ? `"${competitorDetail[detail.option].join(' - ')}"` : `"${competitorDetail[detail.option]}"`) + ',';
            } else {
              string += ','
            }
          }
        });
        if (vm.tournamentIn.line_times.length > 1) {
          string += `"${vm.requestedTime(competitor)}",`;
        }
        vm.onlineTournament.rounds.forEach(function (round, index) {
          let assignment = competitor.assignments[round.id];
          if (assignment == null) {
          
          } else {
            let time;
            if (assignment.line_time != null) time = round.line_times.find(f => f.id === parseInt(assignment.line_time));
            string += (index > 0 ? ',' : '') + (time != null ? `"${time.time}"` : '') + ','
              + (assignment.location ? '"' + assignment.location + '"' : '') + ','
              + (assignment.bale ? assignment.bale : '') + ','
              + (assignment.position ? assignment.position : '');
          }
        });
        string += '\n';
      });
      return string;
    },
    
    tableData() {
      let vm = this;
      let string = '';
      if (Object.keys(vm.round).length === 0) {
        let details = {};
        string += '"First Name", "Last Name","Status","Email"';
        if (this.tournamentIn.line_times.length > 1) {
          string += ',"Requested Time"';
        }
        for (let extra of vm.tournamentIn.payments.pricing_extras ?? []) {
          string += `,"${extra.name} (${extra.type}${extra.amount})"`;
        }
        vm.onlineTournament.regform.forEach(function (detail) {
          if (detail.option !== 'name' && detail.option !== 'email' && detail.type !== 'header') {
            string += ',"' + detail.name + '"';
            details[detail.option] = detail.multiple ? [] : '';
          }
        });
        string += (vm.onlineTournament.targets.length > 1) ? ',"Target"' : '';
        string += '\n'; // string += ',"Team\\Club"\n';
        vm.filteredCompetitors.forEach(function (competitor) {
          let status = [];
          if (!competitor.paid) {
            status.push('unpaid');
          } else if (competitor.paid_reason) {
            status.push(competitor.paid_reason)
          } else {
            status.push('paid')
          }
          if (competitor.wait_list) {
            status.push('wait listed');
          }
          if (competitor.withdrew) {
            status.push('withdrew');
          }
          
          string += '"' + competitor.first_name + '",';
          string += '"' + competitor.last_name + '",';
          if (status.length) {
            string += `"${status.join(' - ')}",`;
          } else {
            string += ',';
          }
          string += `"${competitor.email}",`;
          if (vm.tournamentIn.line_times.length > 1) {
            string += `"${vm.requestedTime(competitor)}",`;
          }
          for (let extra of vm.tournamentIn.payments.pricing_extras ?? []) {
            let selected = false
            if (competitor.pricing_extras && competitor.pricing_extras.length) {
              let exists = [];
              if (extra.hasOwnProperty('id')) {
                exists = competitor.pricing_extras.filter((e) => e.id === extra.id);
              } else {
                exists = competitor.pricing_extras.filter((e) => e.name === extra.name);
              }
              if (exists.length > 0) selected = true;
            }
            string += `"${selected ? 'yes' : 'no'}",`;
          }
          let competitorDetail = Object.assign({}, details);
          
          competitor.regform.forEach(function (detail) {
            competitorDetail[detail.option] = detail.multiple ? detail.multiple : detail.name;
          });
          
          vm.onlineTournament.regform.forEach(function (detail) {
            if (detail.option !== 'name' && detail.option !== 'email' && detail.type !== 'header') {
              if (competitorDetail[detail.option] && competitorDetail[detail.option].length) {
                string += (detail.type === 'checkbox' ? `"${competitorDetail[detail.option].join(' - ')}"` : `"${competitorDetail[detail.option]}"`) + ',';
              } else {
                string += ','
              }
            }
          });
          if (vm.onlineTournament.targets.length <= 1) string = string.slice(0, -1);
          string += vm.targetName(competitor);
        });
      } else {
        string += '"First Name","Last Name","Line Time","Location","' + vm.baleWord + '","Position","Approved for Round"\n';
        vm.filteredCompetitors.forEach(function (competitor) {
          let assignment = competitor.assignments[vm.round.id];
          string += `"${competitor.first_name}",${competitor.last_name ? competitor.last_name : ''},`;
          let time = vm.round.line_times.find(f => f.id === parseInt(assignment.line_time));
          string += (time ? `"${time.time}"` : '') + ','
            + (assignment.location ? '"' + assignment.location + '"' : '') + ','
            + (assignment.bale ? assignment.bale : '') + ','
            + (assignment.position ? assignment.position : '') + ','
            + (assignment.approved ? 'yes' : 'no') + '\n';
        });
      }
      return string
    },
    filteredCompetitors() {
      if (!this.onlineTournament) {
        return null;
      }
      let competitors = this.onlineTournament.competitors;
      if (this.search.length) {
        let search = this.search.toUpperCase();
        competitors = competitors.filter(function (competitor) {
          let first = competitor.first_name != null ? competitor.first_name.toUpperCase() : '';
          let last = competitor.last_name != null ? competitor.last_name.toUpperCase() : '';
          return first.includes(search) || last.includes(search);
        });
      }
      if (this.round && this.round.competitors) {
        if (this.selectedTime) {
          let time = this.selectedTime === 'none' ? null : this.selectedTime.id;
          let matchIds = null;
          if (time) {
            matchIds = this.round.competitors.filter(f => parseInt(f.pivot.line_time) === parseInt(time)).map(a => a.id);
          } else {
            matchIds = this.round.competitors.filter(f => f.pivot.line_time === null).map(a => a.id);
          }
          competitors = competitors.filter(function (f) {
            return matchIds.includes(f.id);
          });
        }
        if (this.selectedLocation) {
          let location = this.selectedLocation === 'none' ? null : this.selectedLocation.name;
          let matchIds = this.round.competitors.filter(f => f.pivot.location === location).map(a => a.id);
          competitors = competitors.filter(function (f) {
            return matchIds.includes(f.id);
          })
        }
        if (this.selectedBale) {
          let bale = this.selectedBale === 'none' ? null : this.selectedBale;
          let matchIds = this.round.competitors.filter(f => f.pivot.bale === bale).map(a => a.id);
          competitors = competitors.filter(function (f) {
            return matchIds.includes(f.id);
          })
        }
        if (this.selectedApproved) {
          let approved = this.selectedApproved === 'yes' ? 1 : 0;
          let matchIds = this.round.competitors.filter(f => f.pivot.approved === approved).map(a => a.id);
          competitors = competitors.filter(function (f) {
            return matchIds.includes(f.id);
          })
        }
      }
      if (this.selectedStatus) {
        let status = this.selectedStatus === 'waitlist' ? 'wait_list' : this.selectedStatus;
        if (status === 'unpaid') {
          competitors = competitors.filter(f => !f.paid)
        } else {
          competitors = competitors.filter(f => f[status])
        }
      }
      if (this.selectedTarget) {
        let target = this.selectedTarget === 'none' ? null : parseInt(this.selectedTarget.id);
        competitors = competitors.filter(f => f.target_id === target);
      }
      if (this.selectedExtra) {
        let vm = this;
        competitors = competitors.filter(function (p) {
          let form = (p.pricing_extras ?? []).filter(f => {
            if (vm.selectedExtra.hasOwnProperty('id')) return f.id === vm.selectedExtra.id;
            return f.name === vm.selectedExtra.name;
          });
          return form.length > 0;
        })
      }
      if (this.selectedOptions) {
        let vm = this;
        let options = vm.selectedOptions.filter(f => f.selected !== null);
        if (options) {
          options.forEach(function (option) {
            let label = vm.selectedOptions.find(f => f.name === option.name).selected === 'none' ? null : option.selected.label;
            competitors = competitors.filter(function (p) {
              let form = p.regform.find(f => f.option === option.name);
              if (form) {
                if (form.multiple) {
                  return form.multiple.includes(label);
                } else {
                  return form.name === label;
                }
              }
            })
          })
        }
      }
      return competitors;
    },
    allBales() {
      return Object.values(this.tournamentIn.bales).map(m => m.name);
    },
  },
  watch: {
    filteredCompetitors() {
      if (this.filteredCompetitors.length && this.$refs.paginator) {
        setTimeout(() => {
          this.$refs.paginator.goToPage(1)
        }, 100);
      }
    }
  },
  mounted() {
    this.getTournament();
  },
  methods: {
    requestedTime(competitor) {
      let index = competitor.line_time;
      if (index == null) return '';
      let time = this.onlineTournament.line_times.find((t) => parseInt(t.id) === parseInt(index));
      if (time) return time.time;
      return '';
    },
    targetName(competitor) {
      if (competitor.target_id != null && this.onlineTournament.targets.length > 1) {
        let targetId = parseInt(competitor.target_id);
        let target = this.onlineTournament.targets.find(f => parseInt(f.id) === targetId);
        if (target != null) return `"${target.name}"\n`;
      }
      return '\n';
    },
    detailInForm(detail) {
      if (!detail.enabled) return false;
      let options = ['name', 'email', 'first_name', 'last_name'];
      return !(options.includes(detail.option) || ['header', 'waiver'].includes(detail.type));
    },
    selectedOption(detail) {
      let value = '';
      if (this.selectedOptions) {
        let selected = this.selectedOptions.find(f => f.name === detail.option).selected;
        if (selected) {
          value = `: ${selected === 'none' ? 'none' : selected.label}`;
        }
      }
      return value;
    },
    updateAvailable(available) {
      this.onlineTournament.available = available;
    },
    sort(column) {
      if (this.sorted.includes(column)) {
        if (['first_name', 'last_name', 'email'].includes(column)) {
          this.filteredCompetitors.sort((a, b) => (a[column] < b[column]) ? 1 : -1);
        } else {
          this.filteredCompetitors.sort((a, b) =>
            a.assignments[this.round.id][column] < b.assignments[this.round.id][column] ? 1 : -1
          );
        }
        let position = this.sorted.indexOf(column);
        this.sorted.splice(position, 1);
      } else {
        if (['first_name', 'last_name', 'email'].includes(column)) {
          this.filteredCompetitors.sort((a, b) => (a[column] > b[column]) ? 1 : -1);
        } else {
          this.filteredCompetitors.sort((a, b) => {
            return a.assignments[this.round.id][column] > b.assignments[this.round.id][column] ? 1 : -1
          });
        }
        this.sorted.push(column);
      }
    },
    allowedToggle() {
      this.onlineTournament.allow_edits = !this.onlineTournament.allow_edits;
      this.$axios.post(`/tournaments/${this.tournamentIn.slug}/manage`,
        {'update': {'allow_edits': this.onlineTournament.allow_edits}});
      this.incrementChange();
    },
    deleteCompetitor(competitor) {
      let exists = this.onlineTournament.competitors.findIndex((f) => {
        return f.id === competitor.id
      });
      if (exists !== -1) {
        this.incrementChange();
        this.onlineTournament.competitors.splice(exists, 1);
      }
    },
    selectTab(tab) {
      this.round = tab;
      if (this.round.name) {
        let previousTab = document.querySelector('.selectedTab');
        previousTab.className = "tabItem";
        
        let newTab = document.querySelector('#round_' + this.round.id);
        newTab.className = "selectedTab";
      } else {
        let previousTab = document.querySelector('.selectedTab');
        previousTab.className = "tabItem";
        
        let newTab = document.querySelector('#mainTab');
        newTab.className = "selectedTab";
      }
    },
    buttonLabel(detail) {
      let label = detail.name;
      if (this.selectedOptions.length) {
        let option = this.selectedOptions.find(f => f.name === detail.option);
        if (option && option.selected) {
          label += ': ';
          if (option.selected === 'none') {
            label += 'none';
          } else {
            label += this.selectedOptions.find(f => f.name === detail.option).selected.label;
          }
        }
      }
      return label;
    },
    email() {
      let vm = this;
      BsAlert.fire({
        title: 'Emailing Selected Archers',
        text: 'Please enter the email subject',
        footer: 'This will be sent only to shown competitors. Make sure that showing is set to "All" if you intend to email entire filtered selection.',
        input: 'text',
        confirmButtonText: 'Next'
      }).then((result) => {
        if (result.value) {
          BsAlert.fire({
            title: 'Compose a Message',
            input: 'textarea',
            footer: 'All emails will include links to their personal competitor management page and the tournament home page.',
            confirmButtonText: 'Send Email',
            showLoaderOnConfirm: true,
            preConfirm: (message) => {
              return this.$axios.post('/email', {
                'to': vm.filteredCompetitors,
                'tournament': vm.onlineTournament,
                'subject': result.value,
                'message': message,
                'type': 'generic',
              })
              .then(({data}) => {
                if (data.success) {
                  return BsAlert.fire({title: 'Messages have been sent!', showCancelButton: false, icon: "success"});
                } else if (data.error) {
                  return BsAlert.fire({title: data.error.message, showCancelButton: false, icon: "error"});
                }
              })
              .catch(function (error) {
                console.log(error);
              });
            },
            allowOutsideClick: () => !BsAlert.isLoading()
          })
        }
      })
    },
    download() {
      BsAlert.fire({
        title: 'Select Download Details',
        showCloseButton: true,
        cancelButtonText: 'Overview and Rounds',
        confirmButtonText: 'Displayed Selection',
        animation: false,
        icon: 'question',
      }).then(({value, dismiss}) => {
        let name = this.tournamentIn.slug;
        if (value) {
          let blob = new Blob([this.tableData], {type: 'text/csv'});
          let link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          name += (Object.keys(this.round).length === 0) ? '_competitors.csv' : '_round_' + this.round.id + '_competitors.csv';
          link.download = name;
          link.click();
        }
        if (dismiss && dismiss === 'cancel') {
          let blob = new Blob([this.comboTable], {type: 'text/csv'});
          let link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          name += '_competitors_overview.csv';
          link.download = name;
          link.click();
        }
      })
      
    },
    copy() {
      BsAlert.fire({
        title: 'Select Copy Details',
        showCloseButton: true,
        cancelButtonText: 'Overview and Rounds',
        confirmButtonText: 'Displayed Selection',
        animation: false,
        icon: 'question',
      }).then(({value, dismiss}) => {
        var el = document.createElement('textarea');
        if (value) {
          el.value = this.tableData;
        } else if (dismiss && dismiss === 'cancel') {
          el.value = this.comboTable;
        }
        el.setAttribute('readonly', '');
        el.style = {position: 'absolute', left: '-9999px'};
        document.body.appendChild(el);
        // Select text inside element
        el.select();
        // Copy text to clipboard
        document.execCommand('copy');
        // Remove temporary element
        document.body.removeChild(el);
        Toast.fire({title: 'Table copied to clipboard', icon: "success"})
      });
    },
    resetRoundFilters() {
      this.selectedLocation = null;
      this.selectedTime = null;
      this.selectedBale = null;
      this.selectedApproved = null;
    },
    clearSearch() {
      this.search = '';
    },
    incrementChange() {
      this.changeCount++;
      this.suiteChange++;
    },
    updateSuite() {
      if (this.suiteChange) {
        this.$axios
        .get('/tournaments/' + this.tournamentIn.slug + '/suite/tournament')
        .then(({data}) => {
          
          this.$store.commit('tournament/SET_TOURNAMENT', {
            'tournament': data.tournament
          });
          this.suiteChange = 0
        })
        .catch(({response}) => {
          console.log(response);
        });
      }
    },
    getTournament() {
      if (this.changeCount) {
        this.$axios
        .get('/tournaments/' + this.tournamentIn.slug + '/show')
        .then(({data}) => {
          this.onlineTournament = data.tournament;
          let emptyArray = [];
          this.onlineTournament.regform.forEach(function (detail) {
            emptyArray.push({'name': detail.option, 'selected': null})
          });
          this.selectedOptions = emptyArray;
          this.changeCount = 0
        })
        .catch(({response}) => {
          console.log(response);
        });
      }
    },
    printSelection() {
      this.printing = true;
      document.body.innerHTML = document.getElementById('printArea').innerHTML;
      window.print();
      window.location.reload()
    },
    updateAll() {
      let vm = this;
      let column = null;
      let value = null;
      let columnList = {
        'line_time': 'Line Time',
        'location': 'Location',
        'bale': this.baleWord,
        'approved': 'Approved for Round'
      };
      let dbVal = null;
      BsAlert.fire({
        title: 'Select Column',
        input: 'select',
        inputOptions: columnList,
        confirmButtonText: 'Next'
      }).then((result) => {
        if (result.value) {
          column = result.value;
          BsAlert.fire({
            title: 'Select Value',
            input: 'select',
            inputOptions: vm.bulkOptions(column),
            confirmButtonText: 'Select Option'
          }).then((result) => {
            if (result.value) {
              value = result.value;
              dbVal = column === 'location' ? vm.bulkOptions(column)[value] : parseInt(value);
              BsAlert.fire({
                title: 'Set all ' + columnList[column] + ' to ' + vm.bulkOptions(column)[value] + '?',
                text: 'This will update all ' + vm.filteredCompetitors.length + ' selected competitors',
                icon: 'warning',
                confirmButtonText: 'Yes',
                showLoaderOnConfirm: true,
                preConfirm: (updated) => {
                  return this.$axios.post(/tournaments/ + this.tournamentIn.slug + '/mass_update', {
                    'column': column,
                    'value': dbVal,
                    'competitors': vm.filteredCompetitors.map(m => m.id),
                    'round': vm.round.id,
                  })
                  .then(({data}) => {
                    if (data.success) {
                      vm.incrementChange();
                      vm.filteredCompetitors.forEach(function (competitor) {
                        if (dbVal === 'remove') {
                          dbVal = null;
                        }
                        competitor.assignments[vm.round.id][column] = dbVal;
                      })
                    } else if (data.error) {
                      return BsAlert.fire({title: data.error.message, icon: "error", showCancelButton: false});
                    }
                  })
                  .catch(function (error) {
                    console.log(error);
                  });
                },
                allowOutsideClick: () => !BsAlert.isLoading()
              })
            }
          })
        }
      })
    },
    bulkOptions(column) {
      let list = {0: 'remove'};
      if (column === 'line_time') {
        this.round.line_times.forEach(f => list[f.id] = f.time);
      } else if (column === 'location') {
        this.round.locations.forEach(f => list[f.id] = f.name);
      } else if (column === 'bale') {
        let max = 0;
        this.round.locations.forEach(f => parseInt(f.bales) > max ? max = parseInt(f.bales) : max = max);
        for (var i = 1; i < max + 1; i++) {
          list[i] = i;
        }
      } else if (column === 'approved') {
        return {0: 'remove', 1: 'approve'};
      }
      return list;
    }
  },
  beforeRouteLeave(to, from, next) {
    this.updateSuite();
    next();
  }
}
</script>

<style scoped>
th {
  white-space: nowrap;
}

.selectedTab {
  @apply bg-gray-200;
}


.btn-group {
  @apply mt-2;
}
</style>
