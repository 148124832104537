import {defineStore} from "pinia";
import axios from 'axios';
import {nextTick} from "vue";

export const useCompetitorStore = defineStore('competitor', {
  state: () => {
    return {
      registrationType: 'self',
      first_name: null,
      last_name: null,
      email: null,
      gender: null,
      phone_number: null,
      city: null,
      state: null,
      country: null,
      membership_number: null,
      customValues: {},
      regform: [],
      showWaiver: false,
      waiver: null,
      waiverObject: null,
      target_id: null,
      line_time: null,
      targets: [],
      times: [],
      defaultFields: ['first_name', 'last_name', 'email', 'gender', 'phone_number', 'city', 'state', 'country', 'membership_number'],
      selectedPurchases: [],
      pricing_extras: [],
      purchases: [],
      priceRestrictions: [],
      optionRestrictions: [],
      targetRestrictions: [],
      bowscoreFee: 2,
      tournamentPrice: 0,
      stripePublishableKey: null,
      stripeToken: null,
      tournamentSlug: null,
      user_id: null,
      error: null,
      loading: false,
      managedArchers: [],
      selectedArchers: [],
      groups: ['all'],
      selectedGroups: [],
      groupValues: [],
      clubTakesPayments: false,
      autoPriceAdjustments: [],
      groupPurchases: [],
      groupTargets: [],
      groupTimes: [],
      payOffline: false,
      submitted: false,
    };
  },
  getters: {
    registrationFee(state) {
      let price = parseInt(this.tournamentPrice);
      if (state.matchedRestriction != null) {
        if (state.matchedRestriction.type === '+') {
          price += parseInt(state.matchedRestriction.amount);
        } else {
          price -= parseInt(state.matchedRestriction.amount);
        }
      }
      return price;
    },
    registrationText(state) {
      let label = 'Registration';
      if (state.matchedLabel != null) {
        return `${state.matchedLabel} Registration`;
      }
      return label;
    },
    matchedRestriction(state) {
      let match = null;
      for (let restriction of state.priceRestrictions) {
        let formItem = state.regform.find((f) => f.id === restriction.regform_id);
        if (formItem === null) continue;
        let name = formItem.option;
        let selection = formItem.multiple.find((f) => f.id === restriction.multiple_id);
        let price = state.pricing_extras[restriction.type_id];
        if (state.customValues[name] === selection.label) return price;
        if (name === 'gender' && state.gender === selection.multiple) return price;
      }
      return match;
    },
    matchedLabel(state) {
      let match = null;
      for (let restriction of state.priceRestrictions) {
        let formItem = state.regform.find((f) => f.id === restriction.regform_id);
        if (formItem === null) continue;
        let name = formItem.option;
        let selection = formItem.multiple.find((f) => f.id === restriction.multiple_id);
        if (state.customValues[name] === selection.label) return selection.label;
        if (name === 'gender' && state.gender === selection.multiple) return state.gender;
      }
      return match;
    },
    registrationTotal(state) {
      let total = 0;
      for (let item of state.selectedPurchases) {
        total += parseInt(item.amount);
      }
      return total + state.bowscoreFee + state.registrationFee;
    },
    typeComplete(state) {
      return state.registrationType !== null;
    },
    groupSelectionComplete(state) {
      if (!state.typeComplete) return false;
      return this.selectedArchers.length > 0;
    },
    groupFormDataComplete(state) {
      if (!state.groupSelectionComplete) return false;
      for (let archer of state.selectedArchers) {
        for (let key of Object.keys(state.groupValues[archer.id])) {
          let option = state.groupValues[archer.id][key];
          if (option === null) {
            let formItem = state.regform.find((f) => f.id == key);
            if (formItem?.type === 'waiver') {
              return true;
            }
            return false;
          }
        }
      }
      return true;
    },
    groupTargetsComplete(state) {
      if (!state.groupFormDataComplete) return false;
      if (state.targets.length < 2) return true;
      for (let archer of state.selectedArchers) {
        debugger;
        if (state.groupTargets[archer.id] === null) return false;
      }
      return true;
    },
    groupTimesComplete(state) {
      if (!state.groupTargetsComplete) return false;
      if (state.times.length < 2) return true;
      for (let archer of state.selectedArchers) {
        if (state.groupTimes[archer.id] === null) return false;
      }
      return true;
    },
    personalComplete(state) {
      if (!state.typeComplete) return false;
      if (state.first_name == null || state.last_name == null) return false;
      for (let item of state.regform) {
        if (state.defaultFields.includes(item.option)) {
          if (item.option === 'first_name') {
            if (state.first_name == null) return false;
          } else if (item.option === 'last_name') {
            if (state.last_name == null) return false;
          } else if (item.option === 'email') {
            if (state.email == null) return false;
          } else if (item.option === 'gender') {
            if (state.gender == null) return false;
          } else if (item.option === 'phone_number') {
            if (state.phone_number == null) return false;
          } else if (item.option === 'city') {
            if (state.city == null) return false;
          } else if (item.option === 'state') {
            if (state.state == null) return false;
          } else if (item.option === 'country') {
            if (state.country == null) return false;
          } else if (item.option === 'membership_number') {
            if (state.membership_number == null) return false;
          }
        }
      }

      return state.first_name != null && state.last_name != null && state.email != null;
    },
    formDataComplete(state) {
      if (!state.timeComplete) return false;
      for (let value of Object.values(state.customValues)) {
        if (value == null) return false;
      }
      return state.waiversSigned;
    },
    targetComplete(state) {
      if (!state.personalComplete) return false;
      if (state.targets.length < 2) return true;
      return state.target_id != null;
    },
    timeComplete(state) {
      if (!state.targetComplete) return false;
      if (state.times.length < 2) return true;
      return state.line_time != null;
    },
    waiversSigned(state) {
      let waiverOptions = state.regform.filter((r) => r.type === 'waiver');
      let waiverLabels = waiverOptions.map((o) => o.option);
      for (let label of waiverLabels) {
        if (state.customValues[label] !== true) return false;
      }
      return true;
    },
    archerNames(state) {
      let list = state.selectedArchers.map((f) => f.name);
      if (list.length > 1) {
        let last = list.pop();
        let next = list.pop();
        list.push(`${next} and ${last}`);
      }
      return list.join(', ');
    },
    nextIncomplete(state) {
      if (state.registrationType === 'self') {
        if (!state.personalComplete) return 'personalCard';
        if (!state.targetComplete) return 'targetCard';
        if (!state.timeComplete) return 'timeCard';
        if (!state.formDataComplete) return 'formCards';
        if (!state.paymentComplete) return 'paymentCard';
        return null;
      } else {
        if (!state.groupFormDataComplete) {
          for (let option of state.regform) {

            if (option.type === 'waiver') {
              if (!state.waiverIsSigned(option)) {
                return 'waiverCard';
              }
            } else {
              for (let archer of state.selectedArchers) {
                if (state.groupValues[archer.id][option.id] == null) return `${option.name}Card`;
              }
            }
          }
          return 'groupFormCards';
        }
        if (!state.groupTargetsComplete) return 'groupTargetCard';
        if (!state.groupTimesComplete) return 'groupTimeCard';
        return 'groupFormCards';
      }

    }
  },
  actions: {
    showIncomplete() {
      this.submitted = true;
      nextTick(() => {
        let card = this.nextIncomplete;
        document.getElementById(card ?? 'personalCard').scrollIntoView({behavior: "smooth"});
      })
    },
    toggleTime(time) {
      if (this.line_time === time.id) {
        this.line_time = null;
      } else {
        this.line_time = time.id;
      }
    },
    toggleTarget(target) {
      if (this.target_id === target.id) {
        this.target_id = null;
      } else {
        this.target_id = target.id;
      }
    },
    archerRegistrationFee(archer) {
      let total = this.tournamentPrice;
      if (this.autoPriceAdjustments.length === 0) return total;
      for (let option of this.regform) {
        if (this.optionHasPriceRestrictions(option)) {
          let price = this.priceForOption(option, archer);
          if (price > total) total = price;
        }
      }
      return total;
    },
    archerRegistrationLabel(archer) {
      let label = 'Registration';
      let total = this.tournamentPrice;
      if (this.autoPriceAdjustments.length === 0) return label;
      for (let option of this.regform) {
        if (this.optionHasPriceRestrictions(option)) {
          let price = this.priceForOption(option, archer);
          if (price > total) {
            let selected = this.groupValues[archer.id][option.id];
            let regformItem = this.regform.find((f) => f.id === option.id);
            let selectedItem = regformItem.multiple.find((f) => f.label === selected);
            label = `${selectedItem.abbr ?? selectedItem.label} Registration`;
          }
        }
      }
      return label;
    },
    archerBreakdown(archer) {
      let list = [`${this.archerRegistrationLabel(archer)} $${this.archerRegistrationFee(archer)}`];
      for (let purchase of this.groupPurchases[archer.id]) {
        let object = this.purchases.find((f) => f.name === purchase);
        list.push(`${object.name} $${object.amount}`);
      }
      list.push(`Bowscore service fee $${this.bowscoreFee}`);
      return list;
    },
    groupTotal() {
      let total = 0;
      for (let archer of this.selectedArchers) {
        total += parseInt(this.archerTotal(archer));
      }
      return total;
    },
    archerTotal(archer) {
      let total = parseInt(this.archerRegistrationFee(archer));
      total += parseInt(this.bowscoreFee);
      total += parseInt(this.purchaseTotal(archer));
      return total;
    },
    purchaseTotal(archer) {
      let total = 0;
      for (let purchase of this.groupPurchases[archer.id]) {
        let object = this.purchases.find((f) => f.name === purchase);
        total += parseInt(object.amount);
      }
      return total;
    },
    pricingExtraForOption(option, selection) {
      let selectedItem = option.multiple.find((f) => f.label === selection);
      if (selectedItem == null) return null;
      let selectedId = selectedItem.id;
      let restriction = this.priceRestrictions.find((f) => f.regform_id === option.id && f.multiple_id === selectedId);
      return restriction ? this.pricing_extras[restriction.type_id] : null;
    },
    priceForOption(option, archer) {
      let selected = this.groupValues[archer.id][option.id];
      let regformItem = this.regform.find((f) => f.id === option.id);
      let selectedItem = regformItem.multiple.find((f) => f.label === selected);
      let total = this.tournamentPrice;
      if (selectedItem == null) return total;
      let selectedId = selectedItem.id;
      for (let restriction of this.priceRestrictions) {
        if (restriction.regform_id === option.id) {
          if (restriction.multiple_id === selectedId) {
            let priceItem = this.pricing_extras[restriction.type_id];
            let price = priceItem.amount;
            if (priceItem.type === '+') return parseInt(total) + parseInt(price);
            return parseInt(total) - parseInt(price);
          }
        }
      }
      return total;
    },
    optionHasPriceRestrictions(option) {
      let id = option.id;
      for (let restriction of this.priceRestrictions) {
        if (restriction.regform_id == id) return true;
      }
      return false;
    },
    disabled(archer, option) {
      if (this.optionRestrictions.length === 0) return false;
      let restrictions = this.optionRestrictions.filter((f) => f.type_id === option.id);
      if (restrictions.length === 0) return false;
      for (let restriction of restrictions) {
        let regformItem = this.regform.find((f) => f.id === restriction.regform_id);
        let badSelection = regformItem.multiple.find((f) => f.id === restriction.multiple_id);
        if (this.groupValues[archer.id][regformItem.name] === badSelection.label) {
          return true;
        }
      }
      return false;
    },
    archerToggled(archer) {
      let index = this.selectedArchers.indexOf(archer);
      if (index >= 0) {
        this.selectedArchers.splice(index, 1);
        let gIndex = this.selectedGroups.indexOf('all');
        if (gIndex >= 0) this.selectedGroups.splice(gIndex, 1)
      } else {
        this.selectedArchers.push(archer);
      }
    },
    groupToggled(group) {
      let index = this.selectedGroups.indexOf(group);
      if (index >= 0) {
        this.selectedGroups.splice(index, 1);
        if (group === 'all') {
          this.selectedArchers = [];
        } else {
          this.removeGroupArchers(group);
        }
      } else {
        if (group === 'all') {
          this.selectedGroups = ['all'];
          this.selectedArchers = [...this.managedArchers];
        } else {
          this.selectedGroups.push(group);
          let allIndex = this.selectedGroups.indexOf('all');
          if (allIndex >= 0) {
            this.selectedGroups.splice(allIndex, 1);
            this.refreshSelectedByGroups();
          } else {
            this.addGroupArchers(group);
          }
        }
      }
    },
    removeGroupArchers(group) {
      let list = JSON.parse(group.archer_ids);
      let others = [];
      for (let group of this.selectedGroups) {
        others = [...others, ...JSON.parse(group.archer_ids)];
      }
      for (let id of list) {
        let archer = this.managedArchers.find((f) => f.id === id);
        if (!others.includes(archer.id)) {
          let archerIndex = this.selectedArchers.indexOf(archer);
          if (archerIndex >= 0) {
            this.selectedArchers.splice(archerIndex, 1);
          }
        }
      }
    },
    refreshSelectedByGroups() {
      this.selectedArchers = [];
      for (let group of this.selectedGroups) {
        this.addGroupArchers(group);
      }
    },
    addGroupArchers(group) {
      let list = JSON.parse(group.archer_ids);
      for (let id of list) {
        let archer = this.managedArchers.find((f) => f.id === id);
        if (!this.selectedArchers.includes(archer)) {
          this.selectedArchers.push(archer);
        }
      }
    },
    submitForm() {
      this.loading = true;
      if (this.registrationType === 'self') {
        let data = this.$state;
        data['payment'] = this.payOffline ? 0 : this.registrationTotal;
        axios.post(`/tournaments/${this.tournamentSlug}/competitors`, data).then(({data}) => {
          if (data.success) {
            window.location.href = `/tournaments/${this.tournamentSlug}`;
          } else {
            this.error = data.error;
            this.loading = false;
          }
        });
      } else {
        let data = {
          'archers': this.selectedArchers,
          'formValues': this.groupValues,
          'purchases': this.getAllGroupPurchases(),
          'payment': this.groupTotal(),
          'targets': this.groupTargets,
          'times': this.groupTimes,
          'stripe': this.stripeToken,
        }
        axios.post(`/tournaments/${this.tournamentSlug}/bulk_register`, data).then(({data}) => {
          if (data.success) {
            window.location.href = `/tournaments/${this.tournamentSlug}`;
          } else {
            this.error = data.error;
            this.loading = false;
          }
        });
      }
    },
    getAllGroupPurchases() {
      let list = {};
      for (let archer of this.selectedArchers) {
        let archerList = [];
        for (let option of this.groupPurchases[archer.id]) {
          let object = this.pricing_extras.find((f) => f.name === option);
          if (object) archerList.push(object);
        }
        for (let formItem of this.regform) {
          if (this.optionHasPriceRestrictions(formItem)) {
            let selection = this.groupValues[archer.id][formItem.id];
            let extra = this.pricingExtraForOption(formItem, selection);
            if (extra) archerList.push(extra);
          }
        }
        list[archer.id] = archerList.length ? archerList : null;
      }
      return list;
    },
    setLineTimes(tournament) {
      this.times = tournament.rounds[0].line_times;
    },
    setDefaults(tournament, user) {
      this.setRegform(tournament.regform);
      this.setRestrictions(tournament.restrictions);
      this.setCustomValuesStarting();
      this.setPricingExtras(tournament.payments.pricing_extras);
      this.targets = tournament.hide_targets ? [] : tournament.targets;
      this.setLineTimes(tournament);
      this.tournamentPrice = tournament.payments.price;
      this.bowscoreFee = tournament.payments.processing_fee;
      this.tournamentSlug = tournament.slug;
      this.managedArchers = user.managed_archers;
      this.clubTakesPayments = tournament.club.stripe?.stripe_publishable_key != null;
      this.setGroupValues(tournament, user);
      this.setUserValues(tournament, user);
    },
    setUserValues(tournament, user) {
      if (user != null) {

        let archer = user.archer;
        let sportId = tournament.sport_id;
        let defaults = archer.defaults.filter((d) => d.sport_id === sportId || d.sport_id == null);
        for (let item of this.regform) {
          let value = this.getFormDefaultValue(item, defaults, archer);
          if (value != null) {
            if (this.customValues.hasOwnProperty(item.option)) {
              this.customValues[item.option] = value;
            } else if (this.defaultFields.includes(item.option)) {
              this.assignDefault(item, value);
            }
          }
        }
        this.first_name ??= user.first_name;
        this.last_name ??= user.last_name;
        this.email ??= user.email;
      }
    },
    assignDefault(item, value) {
      if (item.option === 'first_name') {
        this.first_name = value;
      } else if (item.option === 'last_name') {
        this.last_name = value;
      } else if (item.option === 'email') {
        this.email = value;
      } else if (item.option === 'gender') {
        this.gender = value;
      } else if (item.option === 'phone_number') {
        this.phone_number = value;
      } else if (item.option === 'city') {
        this.city = value;
      } else if (item.option === 'state') {
        this.state = value;
      } else if (item.option === 'country') {
        this.country = value;
      } else if (item.option === 'membership_number') {
        this.membership_number = value;
      }
    },
    setPurchase(archer, option) {
      let index = this.groupPurchases[archer.id].indexOf(option.name);
      if (index >= 0) {
        this.groupPurchases[archer.id].splice(index, 1);
      } else {
        this.groupPurchases[archer.id].push(option.name);
      }
    },
    setGroupValues(tournament, user) {
      this.groups = ['all', ...user.groups];
      let list = {};
      let paymentList = {};
      let targetList = {};
      let timeList = {};
      const sportId = tournament.sport_id;
      for (let archer of this.managedArchers) {
        let sportDefaults = {};
        let defaults = archer.defaults.filter((d) => d.sport_id == sportId || d.sport_id == null);
        for (let item of this.regform) {
          sportDefaults[item.id] = this.getFormDefaultValue(item, defaults, archer);
        }
        list[archer.id] = sportDefaults;
        paymentList[archer.id] = [];
        targetList[archer.id] = null;
        timeList[archer.id] = null;
      }
      this.groupValues = list;
      this.groupPurchases = paymentList;
      this.groupTargets = targetList;
      this.groupTimes = timeList;
    },
    getFormDefaultValue(item, defaults, archer) {
      if (item.option === 'first_name') {
        let exists = defaults.find((f) => f.option === 'first_name');
        if (exists) return exists.value;
        exists = defaults.find((f) => f.option === 'first');
        if (exists) return exists.value;
        return archer.first_name;
      } else if (item.option === 'last_name') {
        let exists = defaults.find((f) => f.option === 'last_name');
        if (exists) return exists.value;
        exists = defaults.find((f) => f.option === 'last');
        if (exists) return exists.value;
        return archer.last_name;
      } else if (item.option === 'email') {
        let exists = defaults.find((f) => f.option === 'email');
        if (exists) return exists.value;
        return archer.email;
      } else {
        let exists = defaults.find((f) => f.option === item.name);
        if (exists) return exists.value;
        exists = defaults.find((f) => f.option === item.option);
        if (exists) return exists.value;
        return null
      }
    },
    purchaseTapped(value) {
      let index = this.selectedPurchases.indexOf(value);
      if (index >= 0) {
        this.selectedPurchases.splice(index, 1);
      } else {
        this.selectedPurchases.push(value);
      }
    },
    waiverIsSigned(waiver) {
      let label = waiver.option;
      if (this.registrationType === 'self') {
        let local = this.customValues[label];
        return local === true;
      } else {
        for (let archerId of Object.keys(this.groupValues)) {
          let accepted = this.groupValues[archerId][waiver.name] === true;
          if (!accepted) return false;
        }
        return true;
      }

    },
    backFromForm() {
      this.showWaiver = false;
      nextTick(() => {
        document.getElementById('waiverCard').scrollIntoView({behavior: "smooth"});
      })
    },
    acceptWaiver(bool) {
      let label = this.waiverObject.option;
      if (this.registrationType === 'self') {
        this.customValues[label] = bool;
      } else {
        for (let archerId of Object.keys(this.groupValues)) {
          this.groupValues[archerId][this.waiverObject.name] = bool;
        }
      }
      this.backFromForm();
      this.waiverObject = null;
    },
    viewWaiverTapped(waiver) {
      this.showWaiver = true;
      this.waiverObject = waiver;
    },
    setRestrictions(data) {
      for (let restriction of data) {
        if (restriction.type === 'target') {
          this.targetRestrictions.push(restriction);
        } else if (restriction.type === 'options') {
          this.optionRestrictions.push(restriction);
        } else {
          this.priceRestrictions.push(restriction);
        }
      }
    },
    setRegform(data) {
      this.regform = data;
    },
    setPricingExtras(data) {
      let autoIds = this.priceRestrictions.map((r) => r.type_id.toString());
      this.pricingExtras = [];
      this.purchases = [];
      this.autoPriceAdjustments = [];
      for (let index in data) {
        let item = data[index];
        if (autoIds.includes(index)) {
          this.autoPriceAdjustments.push(item);
          this.pricing_extras.push(item);
        } else if (item.type === '-') {
          this.pricing_extras.push(item);
        } else {
          if (item.purchase !== false) {
            this.purchases.push(item);
            this.pricing_extras.push(item);
          } else {
            this.pricing_extras.push(item);
          }
        }
      }
    },
    setCustomValuesStarting() {
      for (let item of this.regform) {
        if (!this.defaultFields.includes(item.option)) {
          let value = null;
          for (let option of item.multiple ?? []) {
            if (option.selected) {
              value = option.name;
              break;
            }
          }
          this.customValues[item.option] = value;
        }
      }
    },
    selectedChoice(option, choice) {
      return this.customValues[option.option] === choice.label;
    },
    choiceTapped(option, choice) {
      if (this.customValues[option.option] === choice.label) {
        this.customValues[option.option] = null;
      } else {
        this.customValues[option.option] = choice.label;
      }
    },
    toggleType(type) {
      if (this.registrationType === type) {
        this.registrationType = null;
      } else {
        this.registrationType = type;
      }
    }
  }
});