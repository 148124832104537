<template>
  <div class="h-full w-full">
    <modal-component class="ml-2">
      <template v-slot:open-modal>
        <span type="button" data-toggle="modal" data-backdrop="true" id="openModal" class="hidden"/>
      </template>
      <template v-slot:modal-body>
        <div class="text-center">
          <div class="text-3xl mb-4 border-b font-bold">User Scoring Available</div>
          <div v-for="competitor in time_up" class="mb-4">{{ competitor.name }} Scoring Completed!</div>
          <div @click="closeModal" v-for="competitor in userScoringCompetitors" class="mb-4">
            <router-link :to="`/user_scoring/${competitor.uuid}`">
              <a class="btn btn-blue-600"> Score {{ competitor.name }} </a>
            </router-link>
          </div>
        </div>
      </template>
    </modal-component>
    <div v-if="loadingTournament" class="bg-yellow-400 flex justify-center py-2">
      <div>
        <span class="mr-2"><i class="fas fa-spin fa-spinner"></i></span>
        Loading latest tournament details. Any registration or scoring changes made during this process will be lost.
      </div>
    </div>
    <div v-if="tournament && tournament.id === inc_tournament.id" class="h-full">
      <div v-if="tournament.master_postal_id && !tournament.approved_postal"
           class="bg-red-200 text-red-900 rounded p-2 text-center m-1 text-xl">
        This Tournament has not yet been approved to participate in the
        <a class="text-blue-600" :href="'/tournaments/' + tournament.master.slug">{{ tournament.master.name }}</a>
        postal
      </div>
      <div v-if="locked && $route.name !== 'score' && $route.name !== 'score_ladder'"
           class="bg-blue-600 text-blue-100 flex justify-around p-2">
        <div class="flex flex-col justify-center">
          This device is locked for scoring.
        </div>
        <div @click="lockClicked">
          <span><i class="fas fa-lock-alt fa-2x"></i></span>
        </div>
        <div class="flex flex-col justify-center">
          <div>
            <router-link :to="{name: lockedReturn, params:{ tournamentIn: tournament}}" class="text-blue-100">
              Click here to return to scoring.
            </router-link>
          </div>
        </div>
      </div>
      <lock-device v-if="showLock" @close="showLock = false" :tournament="tournament" :locked="locked"/>
      <div v-if="onlineTournament" class="flex flex-col lg:flex-row h-full bs-right-margin">
        <div v-if="onlineTournament.status === 'draft' && $route.path !== '/manage/edit'" class="w-full">
          <draft-page :slug="onlineTournament.slug"/>
        </div>
        <div v-else-if="onlineTournament.is_master_postal && badPaths.includes($route.path)">
          This page is not available for Master Postal Tournaments
        </div>
        <router-view
          v-else
          @add-device="addDevice"
          @refresh="checkForNew"
          @forceUpdate="getOnlineTournament"
          :tournamentIn="tournament"
          :user="user"
          :updated="updated"
          :api_login="api_login"
          :key="`${$route.fullPath}_${tournament.id}`"
          class="min-w-0 mb-5 w-full"/>
      </div>
      <div v-else class="text-center flex-grow py-32 bs-right-margin">
        <div v-if="loadingMessage">
          <h1 class="mt-4 text-5xl">{{ loadingMessage }}</h1>
          <span class="m-5"><i class="fas fa-spinner fa-spin fa-5x"></i></span>
        </div>
        <div v-else class="mt-4 text-5xl sm:w-2/3 mx-auto">
          There was an error loading the tournament.
        </div>
      </div>
    </div>
    <div v-else class="text-center flex-grow py-32">
      <div v-if="loadingMessage">
        <h1 class="mt-4 text-5xl">{{ loadingMessage }}</h1>
        <span class="m-5"><i class="fas fa-spinner fa-spin fa-5x"></i></span>
      </div>
      <div v-else class="mt-4 text-5xl sm:w-2/3 mx-auto">
        There was an error loading the tournament.
      </div>
    </div>
  </div>
</template>

<script>
import ModalComponent from "../partials/ModalComponent.vue";
import LockDevice from "../scoring/lock/LockDevice.vue";
import moment from "moment";
import {mapWritableState} from "pinia";
import {useDisplayStore} from "../../stores/DisplayStore";
import DraftPage from "../partials/DraftPage.vue";
import {BsAlert} from "../../mixins/swal_mixins";

export default {
  name: "OfflineSuite",
  components: {DraftPage, LockDevice, ModalComponent},
  props: ['inc_tournament', 'inc_user', 'inc_code', 'api_login', 'time_up', 'user_scoring'],
  data() {
    return {
      locked: false,
      onlineTournament: null,
      showLock: false,
      loadingMessage: 'Loading Details',
      usedCode: false,
      store: useDisplayStore(),
      loadingTournament: false,
      code: this.inc_code,
      updated: null,
      badPaths: ['/assignment', '/progress', '/register', '/group', '/score', '/manage/competitors',
        '/manage/import', '/manage/devices', '/manage/assign', '/manage/notifications',
        '/manage/messaging', '/manage/bales',],
    }
  },
  created() {
    if (this.inc_tournament.target_style) {
      let default_locale = this.inc_tournament.target_style === 'Bales' ? 'bow_bale' : 'bow_stake';
      this.$trans.locale = default_locale;
    }
    if (Object.keys(this.code).length) {
      this.store.usedCode = true;
    } else {
      this.store.usedCode = false;
    }
    if (Object.keys(this.code).length && window.location.search !== "") {
      this.loadingMessage = 'Setting up Device';
      this.store.usedCode = true;
      if (this.code.master) {
        let ladder = this.code.ladder_id != null;
        this.$localForage.setItem(this.inc_tournament.id + '_lock', true).then(() => {
          this.$store.dispatch('tournament/setLocked', this.inc_tournament.slug);
          let params = new URLSearchParams(window.location.search);
          
          params.delete('code');
          window.history.replaceState(null, '', '?' + params);
          if (ladder) {
            if (this.$router.currentRoute.path !== '/score_ladder') {
              this.$router.replace({path: '/score_ladder'});
            }
          } else {
            if (this.$router.currentRoute.path !== '/score') {
              this.$router.replace({path: '/score'});
            }
          }
          
          this.afterCreate();
        });
      } else if (this.code.round_id !== null) {
        let round = this.inc_tournament.rounds.find(f => f.id === this.code.round_id);
        let time = round.line_times.find(f => f.id === this.code.line_time);
        let location = round.locations.find(f => f.id === this.code.location);
        this.$localForage.setItem(this.inc_tournament.id + '_lock', true).then(() => {
          this.$localForage.setItem(this.inc_tournament.id + '_locked_bales', [this.code.bale]).then(() => {
            this.$localForage.setItem(this.inc_tournament.id + '_locked_locations', [location]).then(() => {
              this.$localForage.setItem(this.inc_tournament.id + '_locked_times', [time]).then(() => {
                this.$localForage.setItem(this.inc_tournament.id + '_locked_rounds', [round]).then(() => {
                  this.$localForage.setItem(this.inc_tournament.id + '_code', JSON.stringify(this.code)).then(() => {
                    this.$store.dispatch('tournament/setLocked', this.inc_tournament.slug);
                    this.store.$patch({verified: true});
                    let params = new URLSearchParams(window.location.search);
                    params.delete('code');
                    window.history.replaceState(null, '', '?' + params);
                    if (this.$router.currentRoute.path !== '/score') {
                      this.$router.replace({path: '/score'});
                    }
                    this.afterCreate();
                  });
                  
                });
              });
            });
          });
        });
      } else {
        let ladder = this.inc_tournament.ladders.find(f => f.id === this.code.ladder_id);
        this.$localForage.setItem(this.inc_tournament.id + '_lock', true).then(() => {
          this.$localForage.setItem(this.inc_tournament.id + '_locked_ladders', [ladder]).then(() => {
            this.$localForage.setItem(this.inc_tournament.id + '_code', this.code).then(() => {
              this.$store.dispatch('tournament/setLocked', this.inc_tournament.slug);
              this.store.$patch({verified: true});
              let params = new URLSearchParams(window.location.search);
              params.delete('code');
              window.history.replaceState(null, '', '?' + params);
              if (this.$router.currentRoute.path !== '/score_ladder') {
                this.$router.replace({path: '/score_ladder'});
              }
              this.afterCreate();
            });
          });
        });
      }
    } else {
      this.afterCreate();
    }
  },
  
  computed: {
    ...mapWritableState(useDisplayStore, ['shownBales', 'verified', 'restrictedLadders']),
    link() {
      return 'https://bowscore.com/tournaments/' + this.tournament.slug + '#/competitor';
    },
    tournament() {
      return this.$store.getters['tournament/getTournament'];
    },
    user() {
      return this.$store.getters['user/getUser'];
    },
    online() {
      return this.$store.getters['tournament/getOnline'];
    },
    lockedReturn() {
      return this.restrictedLadders.length ? 'score_ladder' : 'score';
    },
    userScoringCompetitors() {
      let list = [];
      for (let competitor of this.user_scoring) {
        if (!competitor.wait_list) list.push(competitor);
      }
      return list;
    }
  },
  watch: {
    $route(to, from) {
      this.checkForNew();
    }
  },
  methods: {
    closeModal() {
      document.getElementById('closeModal').click();
    },
    async checkVersion() {
      await this.$axios.get('/version').then(async ({data}) => {
        if (data.success !== true) return;
        let latest = data.version;
        let time = moment(data.time).local().format('D MMM YY h:mm a');
        let display = `${latest} (${time})`;
        let local = await this.$localForage.getItem('version');
        if (local !== display) {
          await this.$localForage.setItem('version', display);
          this.$store.commit('tournament/CLEAR_TOURNAMENT');
          await this.$localForage.setItem('allTournaments', []);
        }
      });
    },
    async afterCreate() {
      let vm = this;
      window.addEventListener('onunload', function () {
        vm.$store.dispatch('tournament/clearTournament');
      });
      await this.checkVersion();
      this.$store.commit('user/set', {'user': this.inc_user});
      this.$localForage.getItem(this.inc_tournament.id + '_code').then((code) => {
        let stored = this.$store.getters['tournament/getTournament'];
        let path = this.$route.path;
        if (code) {
          this.code = code;
        } else if (Object.keys(this.user_scoring).length && path === '/' && !this.inc_tournament.lock_scoring) {
          document.getElementById('openModal').click();
        }
        if (stored && stored.code && !Object.values(this.code).length) {
          this.loadingTournament = true;
          this.getOnlineTournament();
        } else if (stored && stored.id === this.inc_tournament.id) {
          this.onlineTournament = stored;
          this.checkForNew()
        } else {
          this.$localForage.getItem('allTournaments').then((allTournaments) => {
            if (allTournaments) {
              let existing = allTournaments.findIndex(f => f.id === this.inc_tournament.id);
              if (existing !== -1) {
                let tournament = allTournaments[existing];
                this.$store.commit('tournament/SET_TOURNAMENT', {
                  'tournament': tournament
                });
                this.onlineTournament = tournament;
                this.checkForNew()
              } else {
                this.getOnlineTournament();
              }
            } else {
              this.getOnlineTournament();
            }
          })
        }
      });
      if (this.inc_tournament.slug) {
        this.locked = this.$store.getters['tournament/getLocked'](this.inc_tournament.slug)
      }
    },
    checkForNew() {
      let count = 0;
      let tournament = this.onlineTournament;
      if (tournament == null) tournament = this.inc_tournament;
      if (tournament.score_count) {
        count = tournament.score_count;
      }
      this.$axios.get('/tournaments/' + tournament.slug + '/latest',
        {
          params: {
            'updated_at': tournament.updated_at,
            'is_master': tournament.is_master_postal,
            'score_count': count,
          }
        }
      ).then(({data}) => {
        if (data.update) {
          this.loadingTournament = true;
          this.getOnlineTournament();
        } else {
          this.updated = new Date();
        }
      });
    },
    forceUpdate() {
      this.loadingTournament = true;
      this.$nextTick(() => this.getOnlineTournament());
    },
    getOnlineTournament() {
      let params = {};
      if (Object.values(this.code).length) {
        params = {params: {code: this.code}};
      }
      this.$axios.get('/tournaments/' + this.inc_tournament.slug + '/suite/tournament', params)
      .then(({data}) => {
        this.onlineTournament = data.tournament;
        if (Object.values(this.code).length) {
          data.tournament.code = this.code;
          this.$store.commit('tournament/SET_TOURNAMENT', {
            'tournament': data.tournament
          });
        } else {
          this.$store.commit('tournament/SET_TOURNAMENT', {
            'tournament': this.onlineTournament
          });
        }
        this.updated = new Date();
        this.loadingTournament = false;//unlock edits
      }).catch((error) => {
        this.loadingTournament = false;//unlock edits
        if (!this.onlineTournament) {
          this.loadingMessage = null;
        }
        console.log("axios error", error);
      });
    },
    lockClicked() {
      BsAlert.fire({
        title: 'Enter Lock Code',
        input: 'text',
        inputValidator: (value) => {
          if (value.toLowerCase() !== this.tournament.lock_code.toLowerCase()) {
            return 'Incorrect Code'
          }
          this.showLock = true;
        }
      })
    },
    addDevice(id) {
      this.device_id = id;
    },
  },
}
</script>

<style scoped>

</style>
