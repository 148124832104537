<script setup>

import {ErrorMessage, Field, useField} from "vee-validate";
import {computed, reactive} from 'vue';
import {useCompetitorStore} from "../../../stores/competitorStore";


const store = useCompetitorStore();

const validOptions = computed(() => {
  let list = [];
  for (let option of store.regform) {
    if (store.defaultFields.includes(option.option)) list.push(option);
  }
  return list;
});

const invalid = computed(() => {
  return store.submitted && !store.personalComplete;
});


</script>

<template>
  <div id="personalCard" class="bs-card" :class="invalid ? 'invalid' : ''">
    <div class="bs-card-header">Personal Information</div>
    <div class="flex flex-wrap holder">
      <div v-for="option of validOptions" class="w-full md:w-1/2 p-2">
        <label :for="option.name" class="bs-section-header">{{ option.name }}</label>
        <Field v-if="option.type === 'text'" :name="option.name" class="bs-input-box" v-model="store[option.option]"
               rules="clean|min:2|required"/>
        <div v-else class="flex">
          <div class="radioBox">
            <Field :name="option.name" type="radio" v-model="store.gender" rules="required" value="Male"/>
          </div>
          <div class="radioBox mr-8">Male</div>
          <div class="radioBox">
            <Field :name="option.name" type="radio" v-model="store.gender" rules="required" value="Female"/>
          </div>
          <div class="radioBox">Female</div>
        </div>
        
        <ErrorMessage :name="option.name" class="errorText"/>
      </div>
    </div>
  </div>
</template>

<style scoped>

.invalid {
  border-color: red;
}

.holder {
  margin-left: -.5rem;
  margin-right: -.5rem;
}

.radioBox {
  @apply flex flex-col justify-center mr-2 font-medium text-black;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: .28px;
  height: 32px;
}

.defaultItem {
  @apply w-1/2;
}

input[type="radio"] {
  accent-color: #000000;
  width: 18px;
  height: 18px;
}
</style>