<script setup>

import {Form} from 'vee-validate';
import CompetitorRegistrationType from "./CompetitorRegistrationType.vue";
import CompetitorPersonalInfo from "./CompetitorPersonalInfo.vue";
import {useCompetitorStore} from "../../../stores/competitorStore";
import CompetitorFormData from "./CompetitorFormData.vue";
import {nextTick, onMounted} from "vue";
import CompetitorWaiver from "./CompetitorWaiver.vue";
import CompetitorPurchases from "./CompetitorPurchases.vue";
import CompetitorCost from "./CompetitorCost.vue";
import CompetitorPayment from "./CompetitorPayment.vue";
import GroupSelection from "../group/GroupSelection.vue";
import GroupFormData from "../group/GroupFormData.vue";
import GroupPurchases from "../group/GroupPurchases.vue";
import GroupTotal from "../group/GroupTotal.vue";
import GroupPayment from "../group/GroupPayment.vue";
import CompetitorTarget from "./CompetitorTarget.vue";
import CompetitorTime from "./CompetitorTime.vue";
import GroupTargets from "../group/GroupTargets.vue";
import GroupTimes from "../group/GroupTimes.vue";

const props = defineProps(['tournamentIn', 'user']);
const store = useCompetitorStore();

onMounted(() => {
  store.setDefaults(props.tournamentIn, props.user);
})



</script>

<template>
  <div>
    <div class="main mx-auto">
      <div v-if="tournamentIn.disable_registration" class="flex justify-center">
        Registration is disabled for this event
      </div>
      <div v-else>
        <div v-show="store.showWaiver">
          <div class="title">
            <button @click.prevent="store.backFromForm">
              <i class="fa fa-arrow-left"></i><span class="titleLabel">View and sign the waiver</span>
            </button>
          </div>
          <CompetitorWaiver/>
        </div>
        <div v-show="!store.showWaiver">
          <div class="title">
            <a href="javascript:history.back()">
              <i class="fa-solid fa-lg fa-arrow-left"></i>
              <span class="titleLabel">Register for {{ tournamentIn.name }}</span>
            </a>
          </div>
          <Form>
            <CompetitorRegistrationType/>
            <div v-if="store.registrationType === 'self'">
              <CompetitorPersonalInfo/>
              <CompetitorTarget/>
              <CompetitorTime/>
              <CompetitorFormData/>
              <CompetitorPurchases/>
              <CompetitorCost/>
              <CompetitorPayment :stripe-key="tournamentIn.club?.stripe?.stripe_publishable_key"/>
            </div>
            <div v-else>
              <GroupSelection/>
              <GroupFormData v-if="store.groupSelectionComplete"/>
              <GroupTargets v-if="store.groupSelectionComplete"/>
              <GroupTimes v-if="store.groupSelectionComplete"/>
              <GroupPurchases v-if="store.groupSelectionComplete"/>
              <GroupTotal v-if="store.groupSelectionComplete"/>
              <GroupPayment v-show="store.groupSelectionComplete"
                            :stripe-key="tournamentIn.club?.stripe?.stripe_publishable_key"/>
            </div>
          </Form>
        </div>
      </div>
    
    </div>
    <div class="hidden xl:flex-1"></div>
  </div>


</template>


<style scoped>
.title {
  @apply ml-2;
  margin-bottom: 22px;
}

.titleLabel {
  @apply m-2;
  font-size: 18px;
  font-weight: bold;
  line-height: 22px;
  letter-spacing: .35px;
}

.main {
  width: 100%;
  margin-top: 40px;
}

@media (min-width: 600px) {
  .main {
    width: 600px;
  }
}


</style>
