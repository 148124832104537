<script setup>

import {useTournamentStore} from "../../../stores/TournamentStore";
import {ErrorMessage, Field} from "vee-validate";
import VueDatePicker from "@vuepic/vue-datepicker";
import {computed} from "vue";
import FormCheckbox from "./FormCheckbox.vue";
import moment from "moment";
import {BsAlert} from "../../../mixins/swal_mixins";

const store = useTournamentStore();

const intKey = {
  1: 'One',
  2: 'Two',
  3: 'Three',
  4: 'Four',
  5: 'Five',
  6: 'Six',
  7: 'Seven',
  8: 'Eight',
  9: 'Nine',
  10: 'Ten',
  11: 'Eleven',
  12: 'Twelve'
}

const endWord = computed(() => store.style === 'paper' ? 'end' : 'starting stake');

const capitalize = (word) => word.charAt(0).toUpperCase() + word.slice(1);

const removeTime = (timeId) => {
  let index = store.roundTimes[store.activeRound].indexOf(timeId);
  store.roundTimes[store.activeRound].splice(index, 1);
  store.touched();
}
const roundTimeClicked = (round) => {
  let list = store.otherTimes(round);
  if (list.length === 0) {
    store.addRoundTime();
  } else {
    let options = {};
    for (let time of list) {
      let dateTime = new Date(time.time);
      let momentTime = moment(dateTime);
      options[time.id] = momentTime.format('MM/DD/yyyy hh:mma')
    }
    BsAlert.fire({
      title: 'Add a time',
      input: 'select',
      inputOptions: options,
      showDenyButton: true,
      denyButtonColor: "#FB5202",
      denyButtonText: 'Create new time'
    }).then((value) => {
      if (value.isDenied) {
        store.addRoundTime();
      } else if (value.isConfirmed) {
        store.addRoundTime(value.value)
      }
    });
  }
}

</script>

<template>
  <div>
    <div class="bs-card">
      <div class="bs-section-header">Rounds (select round to edit)</div>
      <div class="flex flex-wrap">
        <div v-for="round of [...Array(store.rounds.length).keys()]" class="mr-2 mb-2">
          <button @click.prevent="store.activeRound = round" class="bs-input-box"
                  :class="store.activeRound === round ? 'active' : ''">
            {{ intKey[round + 1] }}
          </button>
        </div>
      </div>
    </div>
    <div v-for="round of [...Array(store.rounds.length).keys()]">
      <div v-if="round === store.activeRound">
        <div class="bs-card">
          <div class="bs-section-header">Name of round</div>
          <Field v-model="store.rounds[round]['name']" class="bs-input-box"
                 :name="`Round ${round + 1} name`" rules="clean"/>
          <ErrorMessage :name="`Round ${round + 1} name`" class="errorText"/>
        </div>
        <div class="bs-card">
          <div class="bs-card-header">Default target</div>
          <div class="flex flex-wrap">
            <div v-for="target of store.targets">
              <button class="bs-input-box targetBox"
                      @click.prevent="store.roundTargetClicked(target, round)"
                      :class="target === store.rounds[round]['target'] ? 'activeBox' : ''">
                {{ target.name }}
              </button>
            </div>
          </div>
        
        </div>
        <div class="bs-card">
          <div class="bs-card-header">Date & Time</div>
          <div>
            <div class="bs-section-header">Line times</div>
            <div>
              <div v-for="timeId of store.roundTimes[round]" class="bs-input-box mt-3 flex justify-between">
                <div>{{ store.formattedTime(store.times.find((f) => f.id === timeId).time) }}</div>
                <div v-if="store.roundTimes[round].length > 1" class="flex flex-col justify-center"
                     @click.prevent="removeTime(timeId)"><i class="text-stone-400 fa-regular fa-times"></i>
                </div>
              </div>
              <VueDatePicker
                v-if="store.tempTime != null" class="mt-3"
                :is24="false"
                :min-date="store.startDate"
                :max-date="store.endDate != null ? store.endDate : store.startDate"
                :ignore-time-validation="true"
                :key="`Round ${round} Line Time temp`"
                :model-value="store.tempTime.time"
                :start-date="store.startDate"
                :timezone="store.timezone"
                minutes-increment="15"
                time-picker-inline
                prevent-min-max-navigation
                @update:model-value="store.setRoundTime"
                @closed="store.sortRoundTimes(round)">
                <template #input-icon>
                  <div class="flex justify-center ml-3">
                    <i class="fa-regular fa-lg fa-clock-nine"></i>
                  </div>
                </template>
              </VueDatePicker>
              <button v-else @click.prevent="roundTimeClicked(round)" class="lighter ml-2 mt-3">
                <i class="fa-regular fa-plus"></i> Add line time
              </button>
            </div>
          </div>
        </div>
        <!--        TODO: This is not currently supported, but it is in the mockups. Uncomment if support is added-->
        <!--        <div class="bs-card">-->
        <!--          <div class="bs-card-header">{{ baleWord }}s</div>-->
        <!--          <div class="flex">-->
        <!--            <div class="flex-1 mr-4">-->
        <!--              <div class="bs-section-header">Number of {{ baleWord }}s</div>-->
        <!--              <Field type="number" class="bs-input-box" :name="`Round ${round + 1} Count`"-->
        <!--                     v-model="store.rounds[round]['bale_count']"/>-->
        <!--            </div>-->
        <!--            <div class="flex-2">-->
        <!--              <div class="bs-section-header">Archers per {{ baleWord }}</div>-->
        <!--              <div class="flex flex-wrap">-->
        <!--                <div v-for="archer of [...Array(8).keys()]" class="mr-2 mb-2">-->
        <!--                  <button class="bs-input-box "-->
        <!--                          :class="store.rounds[round]['bale_competitors'] === archer + 1 ? 'active': ''"-->
        <!--                          @click.prevent="store.rounds[round]['bale_competitors'] = archer + 1">-->
        <!--                    {{ archer + 1 }}-->
        <!--                  </button>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--          <div class="bs-section-header">Assignments</div>-->
        <!--          <FormCheckbox title="Automatically assign archers"-->
        <!--                        @click="store.rounds[round]['auto'] = !store.rounds[round]['auto']"-->
        <!--                        :active="store.rounds[round]['auto']"/>-->
        <!--          <FormCheckbox :title="`Allow ${baleWord} switching`"-->
        <!--                        :sub-title="`Archers can change their own ${baleWord} assignments`"-->
        <!--                        :active="store.rounds[round]['self_assign']"-->
        <!--                        @click="store.rounds[round]['self_assign'] = !store.rounds[round]['self_assign']"/>-->
        <!--        </div>-->
        <div class="bs-card">
          <div class="bs-card-header">{{ endWord }}s & Arrows</div>
          <div class="flex mb-4">
            <div class="flex-1 mr-4">
              <div class="bs-section-header">Number of {{ endWord }}s</div>
              <Field :name="`Round ${round + 1} end count`"
                     v-model="store.rounds[round]['ends_count']"
                     rules="required|min_value:1|max_value:100"
                     type="number"
                     class="bs-input-box"/>
            </div>
            <div class="flex-1">
              <div class="bs-section-header">Arrows per {{ endWord }}</div>
              <Field :name="`Round ${round + 1} arrow count`"
                     v-model="store.rounds[round]['arrows_count']"
                     rules="required|min_value:1|max_value:100"
                     type="number"
                     class="bs-input-box"/>
            </div>
            <div class="hidden sm:flex flex-1"></div>
          </div>
          <div class="bs-section-header">{{ capitalize(endWord) }} review</div>
          <FormCheckbox :title="`Require archers to review and accept their score after each ${endWord}`"
                        @tapped="store.rounds[round]['accept'] = !store.rounds[round]['accept']"
                        :active="store.rounds[round]['accept']"
          />
        </div>
        <div class="bs-card">
          <div class="bs-section-header">Registration option</div>
          <FormCheckbox title="Do not allow registration for this round until the previous round is scored."
                        @tapped="store.rounds[round]['hidden'] = !store.rounds[round]['hidden']"
                        :active="store.rounds[round]['hidden']"/>
        </div>
      </div>
    
    </div>
  
  </div>
</template>

<style scoped>
.active {
  @apply bg-black text-white;
}

.selectDropdown {
  @apply bg-red-300;
}

.swal2-radio {
  display: grid !important;
}

.activeBox {
  @apply text-white bg-black;
  color: #FFFFFF !important;
}

.targetBox {
  @apply text-black font-medium;
  font-size: 14px;
  letter-spacing: .28px;
  line-height: 17px;
  padding-right: 28px;
  padding-left: 28px;
  margin-right: 8px;
  margin-bottom: 8px;
  width: auto;
}
</style>