<template>
  <div v-if=completion class="flex-grow p-2">
    <div class="text-center text-3xl">Tournament Progress</div>
    <div v-for="round in tournament.rounds">
      <div class="text-3xl flex justify-center">
        <span @click="toggleShowing(round.id)" class="text-blue-600 cursor-pointer mr-2">
          <i :class="showing.includes(round.id) ?'far fa-minus-square':'far fa-plus-square'"></i>
        </span>{{ round.name }}
        <span :class="getColorFromPercentage(completion['rounds'][round.id].percent)" class="pl-2">
          {{ completion['rounds'][round.id].percent }}% Complete
        </span>
        <span v-if="promptClear(round)" class="flex flex-col justify-center pl-2">
          <div class="btn-sm btn-outline-gray-600" @click="clear(round)">Clear Auth Codes</div>
        </span>
      </div>
      <hr>
      <div v-for="time in round.line_times">
        <div v-if="showing.includes(round.id)">
          <div v-for="location in round.locations" class="my-5">
            <div
              v-if="tournament.assignedCompetitors.rounds[round.id].line_times[time.id].locations[location.id].competitors_count">
              <div class="text-2xl flex">
                <span @click="toggleShowing(time.id + '_' + location.id)"
                      class="text-blue-600 cursor-pointer mr-2">
                  <i
                    :class="showing.includes(time.id + '_' + location.id) ?'far fa-minus-square':'far fa-plus-square'"></i>
                </span>{{ time.time }} - {{ location.name }}
                <span
                  class="pl-2"
                  :class="getColorFromPercentage(completion.rounds[round.id].time[time.id].location[location.id].percent)">
                  {{ completion.rounds[round.id].time[time.id].location[location.id].percent }}% Complete
                </span>
                <span v-if="promptClear(round, time.id, location.id)" class="flex flex-col justify-center pl-2">
                  <div class="btn-sm btn-outline-gray-600" @click="clear(round, time, location)">Clear Auth Codes</div>
                </span>
              </div>
              <div v-if="showing.includes(time.id + '_' + location.id)" class="flex"
                   :key="'showing'+time.id+'_'+location.id">
                <div class="flex flex-col justify-center">
                  <div>{{ baleWord(3) }}</div>
                </div>
                <div class="flex flex-col flex-grow min-w-0">
                  <div class="text-center">
                    {{ endWord(1) }}
                  </div>
                  <div>
                    <div class="table-responsive">
                      <table class="table table-bordered">
                        <tr>
                          <th class="table-header"></th>
                          <th class="table-header" v-for="end in round.ends_count">{{ end }}</th>
                        </tr>
                        <tr v-for="bale in locationBalesWithCompetitors(round, time, location)">
                          <td>{{ bale.name }}</td>
                          <td v-for="end in round.ends_count"
                              :class="completionClass(completion.rounds[round.id].time[time.id].location[location.id].bale[bale.name].ends[end])">
                            {{
                              completion.rounds[round.id].time[time.id].location[location.id].bale[bale.name].ends[end]
                            }}%
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <hr>
          </div>
        </div>
      </div>
    </div>
    
    <div v-for="ladder in tournament.ladders">
      <div class="text-3xl flex justify-center">
        <span @click="toggleShowing(ladder.id)" class="text-blue-600 cursor-pointer mr-2">
          <i :class="showing.includes(ladder.id) ?'far fa-minus-square':'far fa-plus-square'"></i>
        </span>{{ ladder.name }}
        <span :class="getColorFromPercentage(completion['ladders'][ladder.id].percent)" class="pl-2">
          {{ completion['ladders'][ladder.id].percent }}% Complete
        </span>
        <!--          <span v-if="promptClear(ladder)" class="flex flex-col justify-center pl-2">-->
        <!--            <div class="btn-sm btn-outline-gray-600" @click="clear(ladder)">Clear Auth Codes</div>-->
        <!--          </span>-->
      </div>
      <div v-if="showing.includes(ladder.id)" v-for="step in stepCount(ladder)">
        <div class="text-2xl flex">
          <span @click="toggleShowing(`${ladder.id}_${step - 1}`)"
                class="text-blue-600 cursor-pointer mr-2">
            <i :class="showing.includes(`${ladder.id}_${step - 1}`) ?'far fa-minus-square':'far fa-plus-square'"></i>
          </span>Step {{ step }}
          <span
            class="pl-2"
            :class="getColorFromPercentage(completion.ladders[ladder.id].step[step - 1].percent)">
            {{ completion.ladders[ladder.id].step[step - 1].percent }}% Complete
          </span>
          <!--          <span v-if="promptClear(round, time.id, location.id)" class="flex flex-col justify-center pl-2">-->
          <!--            <div class="btn-sm btn-outline-gray-600" @click="clear(round, time, location)">Clear Auth Codes</div>-->
          <!--          </span>-->
        </div>
        <div v-if="showing.includes(`${ladder.id}_${step - 1}`)" class="flex"
             :key="`showing${ladder.id}_${step - 1}`">
          <div class="flex flex-col justify-center">
            <div>{{ baleWord(3) }}</div>
          </div>
          <div class="flex flex-col flex-grow min-w-0">
            <div class="text-center">
              {{ endWord(1) }}
            </div>
            <div>
              <div class="table-responsive">
                <table class="table table-bordered">
                  <tr>
                    <th class="table-header"></th>
                    <th class="table-header" v-for="end in ladder.ends_count">{{ end }}</th>
                  </tr>
                  <tr
                    v-if="tournament.assignedCompetitors.ladder[ladder.id].step[step - 1].competitors_count"
                    v-for="bale in tournament.assignedCompetitors.ladder[ladder.id].step[step - 1].bales">
                    <td>{{ bale.bale }}</td>
                    <td v-for="end in ladder.ends_count"
                        :class="completionClass(completion.ladders[ladder.id].step[step - 1].bale[bale.bale].ends[end])">
                      {{ completion.ladders[ladder.id].step[step - 1].bale[bale.bale].ends[end] }}%
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
        <hr>
      </div>
    </div>
  </div>
</template>

<script>

import {BsAlert} from "../../mixins/swal_mixins";

export default {
  name: "ScoringProgress",
  props: ['tournamentIn'],
  data() {
    return {
      showing: [],
      completion: null,
      codes: null,
    }
  },
  computed: {
    isAdmin() {
      return this.$attrs.user && this.$attrs.user.auth_manage;
    },
    tournament() {
      return this.tournamentIn;
    }
  },
  created() {
    this.setCompletion();
    this.getCodes();
  },
  methods: {
    locationBalesWithCompetitors(round, time, location) {
      let list = [];
      let assignments = this.tournament.assignedCompetitors.rounds[round.id].line_times[time.id].locations[location.id];
      for (let bale of location.bales) {
        if (assignments.bales[bale.name].competitors.length) list.push(bale);
      }
      return list;
    },
    endWord(count) {
      let text = this.$trans.choice('search.end', count);
      return `${text[0].toUpperCase()}${text.slice(1)}`;
    },
    baleWord(count) {
      let text = this.$trans.choice('search.bale', count);
      return `${text[0].toUpperCase()}${text.slice(1)}`;
    },
    clear(round, time, location) {
      let selection = `${round.name}`;
      if (time != null) selection += ` - ${time.time}`;
      if (location != null) selection += ` - ${location.name}`;
      BsAlert.fire({
        titleText: `Remove Codes for ${selection}?`,
        icon: 'error',
        html: 'This will permanently erase the codes, they will no longer work for any device setup. Devices that were authorized to score using a quick setup code, will no longer be authorized. New codes may be generated at any time.',
        confirmButtonText: 'Yes, clear codes',
      }).then(({dismiss}) => {
        if (!dismiss) {
          this.$axios.post('/tournaments/' + this.tournament.slug + '/remove_codes', {
            round: round.id,
            time: time ? time.id : null,
            location: location ? location.id : null
          }).then((_) => {
            this.getCodes();
          }).catch(({response}) => {
            console.log(response);
          })
        }
      });
    },
    getCodes() {
      this.$axios
      .get('/tournaments/' + this.tournament.slug + '/get_codes')
      .then(({data}) => {
        this.codes = data.codes;
      })
      .catch(({response}) => {
        console.log(response);
      });
    },
    promptClear(round, time, location) {
      if (!this.isAdmin || this.codes == null) return false;
      if (!(round.id in this.codes)) return false;
      if (this.codes[round.id]['count'] === 0) return false;
      if (time && location) {
        if (this.codes[round.id]['line_times']['count'] === 0) return false;
        if (this.codes[round.id]['line_times'][time]['locations'][location]['count'] === 0) return false;
        return this.completion['rounds'][round.id]['time'][time]['location'][location].percent >= 100;
      }
      return this.completion['rounds'][round.id].percent >= 100;
    },
    setCompletion() {
      let vm = this;
      let complete = {rounds: {}, ladders: {}};
      Object.values(this.tournament.assignedCompetitors.ladder).forEach(function (l) {
        let ladder = vm.tournament.ladders.find(f => f.id === l.ladder_id);
        complete.ladders[ladder.id] = {'step': {}, 'percent': 0};
        let ladderCompetitors = l.competitors_count;
        let ladderComplete = 0;
        let ladderMax = ladderCompetitors * ladder.arrows_count * ladder.ends_count;
        Object.values(l.step).forEach(function (s) {
          complete.ladders[ladder.id].step[s.step] = {'bale': {}, 'percent': 0};
          let stepCompetitors = s.competitors_count;
          let stepComplete = 0;
          let stepMax = stepCompetitors * ladder.arrows_count * ladder.ends_count;
          Object.values(s.bales).forEach(function (bale) {
            let endComplete = {};
            for (let end = 1; end <= ladder.ends_count; end++) {
              endComplete[end] = 0;
            }
            let endCompetitors = 0;
            Object.values(bale.competitors).forEach(function (competitor_id) {
              let cLadder = vm.tournament.scoreList[competitor_id].ladders[`${ladder.id}_${s.step}`];
              if (cLadder != null) {
                ladderComplete += cLadder.complete;
                stepComplete += cLadder.complete;
                let ends = cLadder['ends'];
                endCompetitors++;
                Object.values(ends).forEach(function (end) {
                  endComplete[end.end] += end.complete
                })
              }
            });
            let percent = {};
            Object.keys(endComplete).forEach(end => {
              let extras = vm.tournament.extra_arrows.filter((f) => {
                if (f.round_id !== null) return false;
                return f.ladder_id === l.ladder_id && f.step === s.step && f.end === parseInt(end);
              });
              let endMax = endCompetitors * ladder.arrows_count;
              let endExtras = extras.length * endCompetitors;
              endMax += endExtras;
              stepMax += endExtras;
              ladderMax += endExtras;
              percent[end] = endMax === 0 ? 100 : Math.floor((endComplete[end] / endMax) * 100);
            });
            complete.ladders[ladder.id].step[s.step].bale[bale.bale] = {ends: percent};
          });
          
          let stepPercent = stepMax === 0 ? 100 : Math.floor((stepComplete / stepMax) * 100);
          complete.ladders[ladder.id].step[s.step].percent = stepPercent;
        })
        let ladderPercent = ladderMax === 0 ? 100 : Math.floor((ladderComplete / ladderMax) * 100);
        complete.ladders[ladder.id].percent = ladderPercent;
      })
      Object.values(this.tournament.assignedCompetitors.rounds).forEach(function (round) {
        let tRound = vm.tournament.rounds.find(f => f.id === round.round_id);
        complete.rounds[tRound.id] = {'time': {}, 'percent': 0};
        let roundCompetitors = round.competitors_count;
        let roundComplete = 0;
        Object.values(round.line_times).forEach(function (lineTime) {
          complete.rounds[tRound.id].time[lineTime.line_time_id] = {'location': {}};
          Object.values(lineTime.locations).forEach(function (location) {
            complete.rounds[tRound.id].time[lineTime.line_time_id].location[location.location_id] = {
              'bale': {},
              'percent': 0
            };
            let locTimeCompetitors = location.competitors_count;
            let locTimeComplete = 0;
            Object.values(location.bales).forEach(function (bale) {
              let endComplete = {};
              for (let end = 1; end <= tRound.ends_count; end++) {
                endComplete[end] = 0;
              }
              let endCompetitors = 0;
              Object.values(bale.competitors).forEach(function (competitor_id) {
                let cRound = vm.tournament.scoreList[competitor_id].rounds[tRound.id];
                roundComplete += cRound.complete;
                locTimeComplete += cRound.complete;
                let ends = cRound['ends'];
                endCompetitors++;
                Object.values(ends).forEach(function (end) {
                  endComplete[end.end] += end.complete
                })
              });
              let percent = {};
              Object.keys(endComplete).forEach(end => {
                let endMax = endCompetitors * tRound.arrows_count;
                percent[end] = endMax === 0 ? 100 : Math.floor((endComplete[end] / endMax) * 100);
              });
              complete.rounds[tRound.id].time[lineTime.line_time_id].location[location.location_id].bale[bale.bale] = {ends: percent};
              let locTimeMax = locTimeCompetitors * tRound.arrows_count * tRound.ends_count;
              let locTimePercent = locTimeMax === 0 ? 100 : Math.floor((locTimeComplete / locTimeMax) * 100);
              complete.rounds[tRound.id].time[lineTime.line_time_id].location[location.location_id].percent = locTimePercent;
            });
          })
        });
        let roundMax = roundCompetitors * tRound.arrows_count * tRound.ends_count;
        let roundPercent = roundMax === 0 ? 100 : Math.floor((roundComplete / roundMax) * 100);
        complete.rounds[tRound.id].percent = roundPercent;
      });
      this.completion = complete;
    },
    toggleShowing(id) {
      let exists = this.showing.indexOf(id);
      if (exists !== -1) {
        this.showing.splice(exists, 1);
      } else {
        this.showing.push(id);
      }
    },
    completionClass(complete) {
      if (complete >= 100) {
        return 'bg-blue-600 text-blue-100'
      } else if (complete > 0) {
        return 'bg-blue-200'
      }
    },
    stepCount(ladder) {
      let count = 0;
      if (ladder.shoot_out) {
        return ladder.start_ladder - 1;
      }
      return count;
    },
    getColorFromPercentage(percentage) {
      if (percentage > 0) {
        if (percentage >= 100) {
          return 'text-blue-600';
        } else {
          return 'text-blue-400';
        }
      }
      return 'text-gray-600';
    },
  }
}
</script>

<style scoped>

</style>
