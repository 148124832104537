<script setup>

</script>
<template>
  <div class="banner whiteText flex-col lg:flex-row">
    <div class="flex lg:hidden justify-center w-full mt-8">
      <div class="mr-3 lg:mr-0">Supported</div>
      <div>Sports</div>
    </div>
    <div class="hidden lg:flex w-full justify-evenly">
      <div class="flex flex-col justify-center mt-8 lg:mt-0">
        <div class="mr-3 lg:mr-0">Supported</div>
        <div>Sports</div>
      </div>
      <img src="/img/s3da.svg" width="178" alt="s3da logo">
      <img src="/img/nfaa_logo.svg" width="160" alt="nfaa logo">
      <img src="/img/usa_archery.svg" width="85" alt="usa logo">
      <img src="/img/asa_logo_white.svg" width="145" alt="asa logo">
      <img src="/img/lancaster_classic.svg" width="130" alt="lancaster logo">
      <img src="/img/ibo_logo.svg" width="115" alt="ibo logo">
    </div>
    <div class="flex flex-col lg:hidden">
      <div class="flex">
        <div class="flex-1 flex justify-center">
          <img src="/img/s3da.svg" width="107" alt="s3da logo">
        </div>
        <div class="flex-1 flex justify-center">
          <img src="/img/asa_logo_white.svg" width="125" alt="asa logo">
        </div>
      </div>
      <div class="flex">
        <div class="flex-1 flex justify-center">
          <img src="/img/nfaa_logo.svg" width="96" alt="nfaa logo">
        </div>
        <div class="flex-1 flex justify-center">
          <img src="/img/lancaster_classic.svg" width="102" alt="lancaster logo">
        </div>
      </div>
      <div class="flex mb-8">
        <div class="flex-1 flex justify-center">
          <img src="/img/usa_archery.svg" width="81" alt="usa logo">
        </div>
        <div class="flex-1 flex justify-center">
          <img src="/img/ibo_logo.svg" width="90" alt="ibo logo">
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.whiteText {
  @apply text-white font-bold;
  font-size: 16px;
  font-family: 'Figtree', 'serif';
  text-transform: uppercase;
}

@media (min-width: 1100px) {
  .banner {
    height: 180px !important;
  }
  
  .whiteText {
    font-size: 24px;
  }
}

.banner {
  @apply flex;
  background-color: #FB5202;
  height: 450px;
}
</style>