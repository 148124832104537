<template>
  <div class="flex-grow m-1 sm:m-2">
    <div v-if="user && user.hasOwnProperty('auth_manage') && user.auth_manage"
         class="flex flex-col md:flex-row md:justify-between">
      <h1 class="text-2xl flex-1"> {{ baleWord }} Assignment</h1>
      <div class="flex-1 flex flex-col text-center">
        <div>Automatic Assignments</div>
        <div class="flex justify-between">
          <button @click="bulkAssign('unassigned')" class="btn-sm btn-outline-gray-600">All Unassigned</button>
          <button @click="bulkAssign('all')" class="btn-sm btn-outline-gray-600">All Competitors</button>
          <button @click="bulkAssign('random')" class="btn-sm btn-outline-gray-600">Randomize</button>
          <button @click="clearAll" class="btn-sm btn-outline-gray-600">Clear All</button>
        </div>
        <div v-if="showLoader" class="my-3 text-center">
          <p class="">
            This may take a while with large tournaments.
            Do not leave this page until the assignments are complete.
          </p>
          <div><i class="fas fa-2x fa-spinner fa-spin"></i></div>
          <div class="text-lg">{{ percentage < 100 ? percentage : 99 }}% Complete</div>
        </div>
      </div>
    </div>
    <div v-if="tournament" class="mt-2 ">
      <span class="hidden md:block">
        <ul v-if="tournament.rounds.length > 1" class="tabs mb-3 mt-4" role="tablist">
          <li v-for="(round,index) in tournament.rounds" :class="index === 0?'selectedTab':''"
              :id="'round_' + round.id">
            <a @click.prevent="selectTab(round)" href="">{{ unassignedRound(round) }}</a>
          </li>
        </ul>
      </span>
      <dropdown v-if="tournament.rounds.length > 1" class="md:hidden my-2">
        <template v-slot:trigger>
          <button type="button" class="btn btn-gray-600 dropdown-toggle">
            {{ unassignedRound(selectedRound) }}
            <i class="fas fa-caret-down"></i>
          </button>
        </template>
        <a v-for="(round,index) in tournament.rounds"
           @click.prevent="tab = round.id, selectedRound = round" href="" class="dropdown-item">
          {{ unassignedRound(round) }}
        </a>
      </dropdown>
      <div v-show="readyToScore" class="bg-blue-200 text-blue-600 text-center p-2 rounded">
        <div>All Competitors have been assigned.</div>
        <router-link :to="this.tournament.club_id ? '/score' : '/practice/score'"
                     class="btn-sm btn-outline-blue-600">
          <a> Score Tournament</a>
        </router-link>
      </div>
      <div v-for="round in tournament.rounds">
        <round-assignment
          v-if="round.id === tab"
          :tournament="tournament"
          :round="round"
          :key="round.id"/>
      </div>
    </div>
  </div>
</template>

<script>
import RoundAssignment from "./RoundAssignment.vue";
import {BsAlert} from "../../mixins/swal_mixins";

export default {
  name: "CompetitorAssignment",
  components: {RoundAssignment},
  props: ['tournamentIn', 'user'],
  data() {
    return {
      selectedRound: this.tournamentIn.rounds[0],
      tab: this.tournamentIn.rounds[0].id,
      completed: 0,
      chunkCount: 0,
      showLoader: false,
      missed: []
    }
  },
  watch: {
    percentage(newValue) {
      if (newValue === 100) {
        this.$axios
        .get('/tournaments/' + this.tournament.slug + '/suite/tournament')
        .then(({data}) => {
          this.$store.commit('tournament/SET_TOURNAMENT', {
            'tournament': data.tournament
          });
          this.showLoader = false;
          let missed = this.missed;
          let message = '';
          if (missed) {
            Object.keys(missed).forEach(function (index) {
              Object.keys(missed[index]).forEach(function (field) {
                message += field + ' had no room for ' + missed[index][field].join(', ') + '.<br>';
              })
            })
          }
          this.completed = 0;
          this.chunkCount = 0;
          BsAlert.fire({
            title: 'Assignments complete!',
            html: message,
            showCancelButton: false,
          });
        })
        .catch(({response}) => {
          console.log(response);
        });
        
      }
    }
  },
  computed: {
    baleWord() {
      let text = this.$trans.choice('search.bale', 1);
      return `${text[0].toUpperCase()}${text.slice(1)}`;
    },
    tournament() {
      return this.tournamentIn;
    },
    unassigned() {
      return this.tournament.unassignedCompetitors.rounds;
    },
    readyToScore() {
      let count = 0;
      Object.values(this.unassigned).forEach(function (r) {
        count += r.competitors_count;
      });
      return !count;
    },
    percentage() {
      if (this.completed) {
        let count = this.chunkCount > 0 ? this.chunkCount : 1;
        return Math.round(((this.completed / count) * 100));
      } else {
        return 0;
      }
    }
  },
  methods: {
    unassignedRound(round) {
      let string = round.name;
      let count = this.unassigned[round.id].competitors_count;
      if (count) {
        string += `(${count})`;
      }
      return string;
    },
    selectTab(round) {
      this.tab = round.id;
      this.selectedRound = round;
      let previousTab = document.querySelector('.selectedTab');
      previousTab.className = "tabItem py-3";
      
      let newTab = document.querySelector('#round_' + round.id);
      newTab.className = "selectedTab";
    },
    clearAll() {
      BsAlert.fire({
        titleText: 'Clear all assigned competitors?',
        html: 'Only assigned competitors can be scored.',
        icon: 'warning',
        cancelButtonText: "No, keep them assigned",
        confirmButtonText: 'Yes',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return this.$axios.post('/tournaments/' + this.tournament.slug + '/assignment/clear')
          .then(() => {
            return this.$axios.get('/tournaments/' + this.tournament.slug + '/suite/tournament')
            .then(({data}) => {
              this.$store.commit('tournament/SET_TOURNAMENT', {
                'tournament': data.tournament
              });
            })
          })
        },
      }).then(value => {
        if (value.value) {
          BsAlert.fire({title: 'All assignments have been removed for this tournament', showCancelButton: false});
        }
      })
    },
    bulkAssign(type) {
      let vm = this;
      let message = '';
      if (type === 'all') {
        message += 'This will reassign any previously assigned competitors as well as all unassigned competitors. ';
      } else if (type === 'random') {
        message += 'This will remove any existing assignments. ' +
          'Any auto setting preferences will be used. ' +
          'This can help to split up friends and teammates. ' +
          'Manual adjustments can still be made.'
      }
      message += 'This may take a few minutes on large tournaments.';
      let title = '';
      if (type === 'all') {
        title += 'Auto assign all competitors?';
      } else if (type === 'random') {
        title += 'Randomize assignments for all competitors?'
      } else {
        title += 'Auto assign remaining competitors?'
      }
      BsAlert.fire({
        titleText: title,
        html: message,
        icon: 'question',
        cancelButtonText: "No, I'll do it manually",
        confirmButtonText: 'Yes',
      }).then(({value}) => {
        if (value) {
          let readyCompetitors = new Promise(function (resolve, reject) {
            if (type === 'all' || type === 'random') {
              vm.$axios.post('/tournaments/' + vm.tournament.slug + '/assignment/clear')
              .then(() => {
                resolve();
              }).catch(({response}) => {
                console.log(response);
                reject(response);
              });
            } else {
              resolve();
            }
          });
          readyCompetitors.then(() => {
            vm.showLoader = true;
            let competitorIds = Object.keys(vm.tournament.competitorList);
            if (type === 'random') {
              for (let i = (competitorIds.length - 1); i > 0; i--) {
                const j = Math.floor(Math.random() * i);
                const temp = competitorIds[i];
                competitorIds[i] = competitorIds[j];
                competitorIds[j] = temp
              }
            }
            const [list, chunkSize] = [competitorIds, 200];
            let chunks = new Array(Math.ceil(list.length / chunkSize)).fill().map(_ => list.splice(0, chunkSize));
            vm.chunkCount = chunks.length;
            let index = 0;
            
            function sendChunk(chunk) {
              vm.$axios.post('/tournaments/' + vm.tournament.slug + '/assignment/assign_all', {
                competitors: chunk,
                icon: type,
              })
              .then(({data}) => {
                if (data.missed) {
                  vm.missed = vm.missed.concat(data.missed);
                }
                vm.completed++;
                index++;
                if (vm.percentage !== 100) {
                  debugger;
                  sendChunk(chunks[index])
                }
              }).catch(({response}) => {
                vm.showLoader = false;
                BsAlert.fire({
                  titleText: 'Auto bale failure',
                  html: response.data.message,
                  icon: 'error',
                  showCancelButton: false,
                });
                console.log(response);
              });
            }
            
            sendChunk(chunks[index]);
          }).catch((response) => {
            vm.showLoader = false;
            BsAlert.fire({
              titleText: 'Auto bale failure',
              icon: 'error',
              showCancelButton: false,
            });
            console.log(response);
          });
        }
      })
    }
  }
  
}
</script>

<style scoped>
.nav-link {
  padding-bottom: 0 !important;
}

.nav-link.active {
  @apply border-t-4 border-blue-500 border-b-0 text-2xl pt-0 !important;
}

.btn-outline-blue-dark {
  @apply border-2 border-blue-700 text-blue-700;
}

.btn-outline-blue-dark:hover {
  @apply bg-blue-700 text-blue-100;
}

.selectedTab {
  @apply text-xl text-gray-800 border-t-4 border-blue-600 ;
}
</style>
